import { KycStatus } from '@/api/kyc'
import { NON_VERIFIED_KYC_STATUSES } from '@/constants'

export const setPartnerExternalId = (externalId: string) => {
  window.sessionStorage.setItem('externalId', externalId)
}

export const getPartnerExternalId = () => {
  return window.sessionStorage.getItem('externalId')
}

export const setPartnerSid = (sid: string) => {
  window.sessionStorage.setItem('sid', sid)
}

export const getPartnerSid = () => {
  return window.sessionStorage.getItem('sid')
}

export const clearPartnerKycInfo = () => {
  window.sessionStorage.removeItem('sid')
  window.sessionStorage.removeItem('externalId')
}

export const isKycPassed = (kycStatus?: string) => {
  if (!kycStatus) {
    return false
  }

  return [
    KycStatus.TRUSTED,
    KycStatus.APPROVED,
    KycStatus.REVIEWED,
    KycStatus.WAITING_FOR_APPROVAL,
  ].includes(kycStatus as KycStatus)
}

export const isNonVerifiedKycStatus = (kycStatus: KycStatus) => {
  if (!kycStatus) {
    return false
  }

  return NON_VERIFIED_KYC_STATUSES.includes(kycStatus)
}
