import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

interface IUseSetDefaultLanguageProps {
  isUILoaded: boolean
  merchantDefaultLocale?: string
}

export default function useSetDefaultLanguage({
  isUILoaded,
  merchantDefaultLocale,
}: IUseSetDefaultLanguageProps) {
  const { i18n } = useTranslation()

  const storedLocale = window.localStorage.getItem('lang')

  useEffect(() => {
    if (storedLocale) {
      return
    }

    if (isUILoaded && merchantDefaultLocale) {
      i18n.changeLanguage(merchantDefaultLocale)
    }
  }, [isUILoaded, merchantDefaultLocale, storedLocale, i18n])
}
