import React from 'react';
import { useTranslation } from 'react-i18next';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useAccountFormStyles } from '../../../../hooks/useAccountFormStyles';
import {Form} from 'react-final-form';
import {Input} from '../../../../components/Form';
import {composeValidators, equalWith, passwordValid, required} from '../../../../validators';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import plural from '../../../../helpers/plural';
import useGlobalStyles from '../../../../hooks/useGlobalStyles';
import ServerMessage from '../../../../components/UI/ServerMessage';

interface IProps {
  enabled: boolean;
  isConfirm: boolean;
  confirmEnable: any;
  sending: boolean;
  onCancel: any;
  resendTimeout: number;
  passwordVal: string;
  email: string;
  setPassword: any;
  changePassword: any;
  setVal: any;
  error?: string;
}

export default function PasswordForm({
  enabled,
  isConfirm,
  setPassword,
  changePassword,
  setVal,
  confirmEnable,
  sending,
  onCancel,
  resendTimeout,
  passwordVal,
  email,
  error
}: IProps) {
  const accountFormClasses = useAccountFormStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  const pwdInitialValues: any = {
    password: '',
    confirmPassword: ''
  };

  if (enabled) {
    pwdInitialValues.oldPassword = '';
  }

  return (
    <TableRow>
      <TableCell colSpan={3} className="form">
        {isConfirm && (
          <div>
            <Form
              onSubmit={confirmEnable}
              initialValues={{ code: '', password: passwordVal }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className={accountFormClasses.row}>
                    <Input
                      name="code"
                      label={t('labels.Verification code')}
                      validate={(v: string) => required(v)}
                      fullWidth
                      autoFocus
                      disabled={sending}
                    />
                  </div>
                  <div className={accountFormClasses.row}>
                    <Button
                      className={globalClasses.button}
                      disabled={sending}
                      type="submit"
                      variant="contained"
                      component="button"
                      color="primary"
                    >
                      {sending
                        ? <CircularProgress size={22} color="inherit"/>
                        : <span>{t('Confirm')}</span>
                      }
                    </Button>
                  </div>
                  <div className={accountFormClasses.row}>
                    <Button
                      onClick={onCancel}
                      className={globalClasses.button}
                      disabled={sending}
                    >
                      {t('Cancel')}
                    </Button>
                  </div>
                  <Typography variant="body2" component="div" translate="no">
                    <p>{t('verificationCodeSent')}</p>
                    {resendTimeout > 0
                      ? (
                        <p>
                          {t('Resend confirmation code in')} {resendTimeout} {t(plural(resendTimeout, ['secPlural1', 'secPlural2', 'secPlural5']))}.
                        </p>
                      ) : (
                        <Link
                          onClick={() => setPassword({ email, password: passwordVal })}
                          className={accountFormClasses.link}
                        >
                          {t('Resend code')}
                        </Link>
                      )}
                  </Typography>
                </form>
              )}
            />
          </div>
        )}
        {!isConfirm && (
          <div>
            <Form
              onSubmit={(values) => {
                if (enabled) {
                  return changePassword({ oldPassword: values.oldPassword, newPassword: values.password });
                }
                setVal(values.password);
                setPassword({ email, password: values.password })
              }}
              initialValues={pwdInitialValues}
              render={({ handleSubmit, values, form }) => {
                const pwdFieldState = form.getFieldState('password');
                return (
                  <form onSubmit={handleSubmit}>
                    {enabled && (
                      <div>
                        {error && <ServerMessage type="error" text={error} />}
                        <div>
                          <Input
                            name="oldPassword"
                            label={t('Current password')}
                            validate={(v: string) => required(v)}
                            type="password"
                            autoComplete="new-password"
                            fullWidth
                          />
                        </div>
                      </div>
                    )}
                    <div style={{ position: 'relative' }}>
                      <Input
                        name="password"
                        label={t(enabled ? 'New password' : 'Password')}
                        validate={composeValidators(required, passwordValid)}
                        type="password"
                        autoComplete="new-password"
                        fullWidth
                      />
                      {(!pwdFieldState || (!pwdFieldState.touched && !pwdFieldState.valid)) && (
                        <FormHelperText variant="filled">{t('validation.passwordNotValid')}</FormHelperText>
                      )}
                    </div>
                    <div>
                      <Input
                        name="passwordConfirm"
                        label={t('Confirm password')}
                        validate={composeValidators(required, (v: any) => equalWith(v, values.password))}
                        type="password"
                        autoComplete="new-password"
                        fullWidth
                      />
                    </div>
                    <div className={accountFormClasses.row}>
                      <Button
                        className={globalClasses.button}
                        disabled={sending}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {sending
                          ? <CircularProgress size={22} color="inherit"/>
                          : <span>{t('Save')}</span>
                        }
                      </Button>
                    </div>
                    <div className={accountFormClasses.row}>
                      <Button
                        className={globalClasses.button}
                        onClick={onCancel}
                        disabled={sending}
                      >
                        {t('Cancel')}
                      </Button>
                    </div>
                  </form>
                )
              }}
            />
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}
