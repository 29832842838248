import { decodeAndSplitString } from '@/helpers/encodingUtils'

export const getPaymentDetailsFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const encodedString = searchParams.get('pd')

  if (!encodedString) {
    return { entity: '', code: '' }
  }

  const [entity = '', code = ''] = decodeAndSplitString(encodedString)
  return { entity, code }
}
