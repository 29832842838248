import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import * as Sentry from '@sentry/react'

import { OrderProcessingError, OrderStatus } from '../../../../api/order'
import { PaymentNext, PaymentSource } from '../../../../api/payment'
import { useCardsList } from '../../../../api/private/payment'
import { IAPM, useCountries, useStatesList } from '../../../../api/settings'

import PaymentFrame from '../../../../components/PaymentFrame'
import TXLimitError, { ILimitError } from '../../../../components/TxLimitsError'
import ServerMessage from '../../../../components/UI/ServerMessage'
import apmConfig from '../../../../config/apm.json'
import useAPM, { getCardOption } from '../../../../hooks/payment/useAPM'
import usePayment from '../../../../hooks/payment/usePayment'
import useResidence from '../../../../hooks/payment/useResidence'
import useGlobalStyles from '../../../../hooks/useGlobalStyles'
import ResidenceRestricted from '../../../Account/Profile/ResidenceRestricted'
import StateRestricted from '../../../Account/Profile/StateRestricted'
import CurrencyRestricted from '../../../Account/Profile/CurrencyRestricted'
import AddressAndTerms, {
  IAddressAndTermsProps,
} from '../../../components/AddressAndTerms'
import APMForm from '../../../components/APM'
import APMSelect from '../../../components/APMSelect'
import CardForm from '../../../components/Card'
import usePaymentFormStyles from '../../../components/usePaymentFormStyles'
import ThreeDS from '../../../Payment/ThreeDS'
import Fingerprint from '../../../Payment/Fingerprint'
import FormChangeConfirm from '../auth/FormChangeConfirm'
import BillingFields from '../BillingForm'
import useFormStyles from '../useFormStyles'
import VerificationForm from '../VerificationForm'
import OrderDetails from '../../../components/Details'
import PayFormLayout from './PayFormLayout'
import MobilePay from '../../../components/MobilePay'
import KYCStatus from '../../../components/KYCStatus'
import usePreVerify from '../../../../hooks/payment/usePreVerify'
import { ActiveForm } from '@/store/reducers/routing'
import { setActiveForm } from '@/store/actions/routing'
import { ILangType } from '@/locales'
import { ICustomer } from '@/types/customer'
import { ICurrency } from '@/types/currency'

import PayFirstStep from './PayFirstStep'
import PayUSFlow from './PayUSFlow'
import { ICountryState } from '@/types/country'
import { hotJarSendEvent, hotJarSendUserInfo } from '@/helpers/hotJar'
import { postMessage } from '@/helpers/postMessage'
import { IPostOrderData } from '@/types/webview'
import { USA_CODE } from '@/helpers/usFlowHelper'
import { countryByApm } from '@/helpers/countryByApm'
import {
  useCustomerCountrySet,
  useCustomerWalletStatus,
  useCustomerWalletVerify,
  useCustomerWalletKycToken,
} from '@/api/customer'
import { useCustomerInfo } from '@/api/customer'
import {
  MIRROR,
  RESULT_STEPS,
  VERIFICATION_STEPS,
  isSf,
  IS_PROD,
} from '@/helpers/constants'
import { getResidenceFromStorage } from '@/helpers/getResidenceFromStorage'
import checkoutScreen from '@/config/checkoutScreen.json'
import { getApmView } from '@/helpers/getApmView'
import { defineNFTFlow } from '@/helpers/nftHelpers/nftFlow'
import { getSupportEmail, isChainValley } from '@/helpers/mirrorHelper'
import { getHasApplePay } from '@/helpers/applePay'
import { WIIDGET_ENV } from '@/helpers/constants'
import useHasGooglePay from '@/hooks/useHasGooglePay'
import { filteredApms } from '@/helpers/apmMap'
import useChangeLanguage from '@/hooks/useChangeLanguage'
import RiskDisclosureModal, {
  IRiskDisclosureModalHandles,
} from '@/components/RiskDisclosureModal'
import { useAcceptRiskDisclosure } from '@/api/private/account'
import ComplianceScreen from '@/views/components/ComplianceScreen'
import { WalletVerificationStatus } from '@/types'
import SumSubWalletVerification from '../verification/SumSubWalletVerification'
import { EMPTY_BILLING_ADDRESS } from '@/constants'
import WalletVerificationPending from '@/views/components/WalletVerificationPending'
import QRPaymentModal, {
  IQRPaymentModalHandles,
} from '@/components/QRPaymentModal'
import PayFinish from '@/views/components/PayFinish'

interface IProps {
  order: any
  orderError?: any
  ui: any
  setCustomer: any
  customer: ICustomer
  currencies: ICurrency[]
  loading?: boolean
  limitAcceptError?: ILimitError
  acceptError?: string
  setProcessingError(error: OrderProcessingError): void

  withToken?: boolean
  confirmFormChange?: boolean
  setConfirmFormChange?(v: boolean): void
  setCreateNewOrder?(v: boolean): void
}

export default function PaymentForm({
  order,
  orderError,
  ui,
  customer,
  setCustomer,
  loading,
  limitAcceptError,
  acceptError,
  withToken,
  setConfirmFormChange = () => null,
  confirmFormChange,
  setCreateNewOrder = () => null,
  setProcessingError,
  currencies,
}: IProps) {
  const paymentFormClasses = usePaymentFormStyles()
  let { step } = useParams<any>()
  if (!step) {
    step = 'card'
  }

  const dispatch = useDispatch()
  const { activeForm } = useSelector((state: any) => state.routing)
  if (activeForm === ActiveForm.OTP) {
    dispatch(setActiveForm('')) //TODO need implement routing
  }

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const lang = language.split('-')[0] as ILangType
  const history = useHistory()
  const classes = usePaymentFormStyles()
  const globalClasses = useGlobalStyles()
  const [processingResult, setProcessingResult] = useState<string | undefined>()
  const nextStep = (s?: string) => {
    if (RESULT_STEPS.includes(String(s))) {
      setProcessingResult(s)
    }
    // debugger
    return history.push(
      `/order/${order.id}${s ? `/${s}` : ''}${window.location.search}`
    )
  }

  // checkboxes
  const addressAcceptField = useRef(null)
  const termsAcceptFieldRef = useRef(null)
  const [termsAction, setTermsAction] = useState<undefined | any>()
  const [termsError, setTermsError] = useState<string | undefined>()
  // residence
  const storageResidence = getResidenceFromStorage()
  const [notSupportedCountry, setNotSupportedCountry] = useState<
    any | undefined
  >()
  const [notSupportedState, setNotSupportedState] = useState<boolean>(false)
  const [notSupportedCurrency, setNotSupportedCurrency] =
    useState<boolean>(false)

  const [billing, setBilling] = useState(false)
  const [kycWalletToken, setKycWalletToken] = useState('')
  const [isWalletStatusPending, setIsWalletStatusPending] = useState(false)
  const [isNeedWalletConfirmation, setIsNeedWalletConfirmation] =
    useState(false)

  const [unavailable, setUnavailable] = useState(false)
  const [serverError, setServerError] = useState(orderError?.type || '')

  useChangeLanguage(order?.apm)

  const [{ data: countriesData }, loadingCountries, loadCountries] =
    useCountries()
  const [{ data: customerInfo }, customerLoading, loadCustomer] =
    useCustomerInfo()

  const [send] = useCustomerCountrySet(() => null)

  const [{ data: statesData }, loadingStates, loadStates] = useStatesList()

  const [{ data: cards }, loadingCards, loadCards] = useCardsList()
  const cardOption = getCardOption(t)
  const {
    global: { logo },
  } = ui
  const lsErr = window.localStorage.getItem('pe')
  let resErr
  if (lsErr) {
    const parsed = JSON.parse(lsErr)
    if (parsed.oid === order.id) {
      resErr = parsed.e
    }
  }

  const merchantId =
    new URLSearchParams(window.location.search).get('pid') ?? ''

  const [apmDescriptionIsVisible, setApmDescriptionIsVisible] = useState(false)
  const [requestIsSent, setRequestIsSent] = useState(false)

  // init payment & pay
  const walletStatusCheckTimeout = useRef<NodeJS.Timeout | null>(null)
  const [apmError, setApmError] = useState(false)
  const [paymentData, setPaymentData] = useState<any>({})
  const [auth3d, setAuth3d] = useState(false)
  const [cardId, setCardId] = useState<number | undefined>()
  const [formData, setFormData] = useState<any>()
  const [applePayStarted, setApplePayStarted] = useState(false)
  const [googlePayStarted, setGooglePayStarted] = useState(false)
  const [resultError, setResultError] = useState<undefined | string>(resErr)
  const [isLoadForm, setIsLoadForm] = useState(isSf)
  const isNFTFlow = defineNFTFlow(order?.currency)
  const riskDisclosureModalRef = useRef<IRiskDisclosureModalHandles>(null)
  const qrPaymentModalRef = useRef<IQRPaymentModalHandles>(null)

  const [acceptRiskDisclosure, acceptingRiskDisclosure] =
    useAcceptRiskDisclosure((res: Record<string, any>) => {
      if (res.success) {
        setRiskDisclosureAcceptedError('')
        riskDisclosureModalRef.current?.hide()

        if (googlePayStarted) {
          initPayment({
            source: PaymentSource.APM,
            orderId: order.id,
            apm: { id: 'googlepay', inputs: {} },
          })
          return
        }

        if (!googlePayStarted && !applePayStarted) {
          if (apm === cardOption.id) {
            initPayment({
              source: PaymentSource.CardInternal,
              orderId: order.id,
              threeDs: 'ver2',
            })
          } else {
            initPayment(formData)
          }
          return
        }
      } else {
        setRiskDisclosureAcceptedError('')
        setServerError('somethingWentWrong')
        riskDisclosureModalRef.current?.hide()
      }
    })

  const showApm =
    !isNFTFlow &&
    !apmConfig.hideForMerchants[
      WIIDGET_ENV as keyof typeof apmConfig.hideForMerchants
    ]?.includes(order.mId)
  const {
    apm,
    apms,
    loadAPMs,
    loadingAPMs,
    setApm,
    apmAmount,
    apmAmountConverting,
    apmsLoaded,
  } = useAPM(order, customer, showApm)

  const [[kycRequired, checkingKyc, waitingForKyc, flow], setData] =
    usePreVerify(order, customer, setCustomer, apm)

  const isUsFlow = flow === 'utp-US' && (!customer.ssn || !customer.phone)

  const {
    initPayment,
    payNew,
    pay,
    paymentInProgress,
    paymentError,
    form3ds,
    formFingerprint,
    setPaymentError,
    checkPaymentStatus,
    payMobile,
    initPaymentMobile,
    riskDisclosureAcceptedError,
    setRiskDisclosureAcceptedError,
    isKycRejected,
    isKytVerificationError,
    setIsKytVerificationError,
    qrCode,
    setQRCode,
  } = usePayment({
    order,
    nextStep,
    setServerError,
    setPaymentData,
    setBilling,
    setProcessingError,
    termsAccepted: customer.termsAccepted && customer.privacyPolicyAccepted,
    onProviderError: order.sf
      ? (error: string) => {
        window.localStorage.setItem(
          'pe',
          JSON.stringify({ oid: order.id, e: error })
        )
        setResultError(error)
        nextStep('error')
      }
      : undefined,
    kycRequired,
    setIsLoadForm,
    customer,
    apm: apms?.find((a: IAPM) => a.id === apm),
  })

  const [loadKycWalletToken, isLoadingKycWalletToken] =
    useCustomerWalletKycToken((res: Record<string, any>) => {
      if (res?.data?.authToken) {
        setIsKytVerificationError(false)
        return setKycWalletToken(res.data.authToken)
      } else {
        setPaymentError('somethingWentWrong')
      }
    })

  const [checkWalletStatus, isCheckingWalletStatus] = useCustomerWalletStatus(
    (res: Record<string, any>) => {
      if (res.success) {
        if (
          res?.data?.verificationStatus === WalletVerificationStatus.APPROVED
        ) {
          setIsWalletStatusPending(false)
          if (googlePayStarted) {
            initPayment({
              source: PaymentSource.APM,
              orderId: order.id,
              apm: { id: 'googlepay', inputs: {} },
            })
            return
          }
          if (apm === cardOption.id) {
            initPayment({
              source: PaymentSource.CardInternal,
              orderId: order.id,
              threeDs: 'ver2',
            })
          } else {
            initPayment(formData)
          }
          return
        }

        if (
          res?.data?.verificationStatus ===
          WalletVerificationStatus.NEED_CONFIRMATION
        ) {
          setIsWalletStatusPending(false)
          return loadKycWalletToken({ orderId: order.id })
        }

        if (
          res?.data?.verificationStatus === WalletVerificationStatus.REJECTED
        ) {
          setIsWalletStatusPending(false)
          window.localStorage.setItem(
            'pe',
            JSON.stringify({ oid: order.id, e: 'KYT_REJECTED' })
          )
          setResultError('KYT_REJECTED')
          nextStep('error')
          return
        }

        walletStatusCheckTimeout.current = setTimeout(
          () => checkWalletStatus({ orderId: order.id }),
          5000
        )
      } else {
        setIsWalletStatusPending(false)
        setPaymentError('somethingWentWrong')
      }
    }
  )

  const [verifyWallet, isWalletVerifying] = useCustomerWalletVerify(
    (res: Record<string, any>) => {
      if (res?.success) {
        if (
          res?.data?.verificationStatus === WalletVerificationStatus.APPROVED
        ) {
          if (googlePayStarted) {
            initPayment({
              source: PaymentSource.APM,
              orderId: order.id,
              apm: { id: 'googlepay', inputs: {} },
            })
            return
          }
          if (apm === cardOption.id) {
            initPayment({
              source: PaymentSource.CardInternal,
              orderId: order.id,
              threeDs: 'ver2',
            })
          } else {
            initPayment(formData)
          }
          return
        }

        if (
          res?.data?.verificationStatus === WalletVerificationStatus.REJECTED
        ) {
          window.localStorage.setItem(
            'pe',
            JSON.stringify({ oid: order.id, e: 'KYT_REJECTED' })
          )
          setResultError('KYT_REJECTED')
          nextStep('error')
          return
        }

        if (
          res?.data?.verificationStatus ===
          WalletVerificationStatus.NEED_CONFIRMATION
        ) {
          return setIsNeedWalletConfirmation(true)
        }

        setIsWalletStatusPending(true)
        return checkWalletStatus({ orderId: order.id })
      } else {
        setPaymentError('somethingWentWrong')
      }
    }
  )

  const hasGooglePay = useHasGooglePay(showApm, apms, customer.id)

  const {
    billingAddress,
    setBillingAddress,
    saveBilling,
    saveCountry,
    saveState,
    residenceSaving,
    validateCountry,
    validateBillingAddress,
    countryError,
    cityError,
    setCityError,
    zipcodeError,
    setZipcodeError,
    streetAddressError,
    setStreetAddressError,
    dateOfBirthError,
    firstNameError,
    setFirstNameError,
    lastNameError,
    placeOfBirthError,
    setPlaceOfBirthError,
    setDateOfBirthError,
    setLastNameError,
  } = useResidence({
    billingSaveCb: (res: any) => {
      if (!res.success) {
        setServerError('somethingWentWrong')
      }
    },
    customer,
    billing,
  })

  function makePay() {
    const paymentId = paymentData.id
    const data = formData

    if (!paymentId || !data) {
      return
    }
    // make payment with existing card
    if (data.cardId) {
      return pay({ ...data, paymentId })
    }
    // make payment with new card
    return payNew({ ...data, paymentId })
  }

  const formClasses = useFormStyles({ buttonColor: ui.form.buttonColor })
  const [paymentResult, setPaymentResult] = useState<string | undefined>()

  function getMessageFromFrame(msg: any) {
    try {
      const mess = JSON.parse(msg.data)
      if (mess.type !== 'paymentResult') {
        return
      }
      if (!mess.success && !mess.pending) {
        setResultError(mess.error)
        window.localStorage.setItem(
          'pe',
          JSON.stringify({ oid: order.id, e: mess.error })
        )
        return nextStep('error')
      }
      setPaymentResult(mess.pending ? 'pending' : 'success')
      nextStep('verification')
    } catch {}
  }

  const canStartHotJarRecording =
    (isSf && IS_PROD) ||
    [115798520742076, 77478270520860].includes(order.acceptedById)

  // @ts-ignore
  const viewWithoutCheckout = checkoutScreen.merchantsWithoutCheckoutScreen[
    WIIDGET_ENV
  ]?.includes(order?.mId)

  const showApmView = getApmView(step, order)

  useEffect(() => {
    window.localStorage.setItem('orderId', order.id)
    if (!order.sf && storageResidence) {
      if (
        storageResidence?.id === Number(order.id) &&
        storageResidence?.code !== USA_CODE
      ) {
        send({ country: storageResidence.code })
      }
    }
  }, [order.sf, order.id])

  useEffect(() => {
    loadCountries()

    if (canStartHotJarRecording) {
      hotJarSendEvent()
    }

    if (order.acceptedById && order.id && order?.apm && merchantId) {
      hotJarSendUserInfo({
        userId: order.acceptedById,
        orderId: order.id,
        apm: order.apm,
        merchantId,
      })
    }

    if (
      customer.countryByIp === USA_CODE ||
      (customer.countryByIp !== USA_CODE && !order.sf)
    ) {
      loadStates()
    }

    if (order.status === OrderStatus.VERIFICATION && step !== 'verification') {
      nextStep('verification')
      return
    }
    if (['SUSPICIOUS', 'EXECUTED', 'SUCCESS'].includes(order.status)) {
      nextStep('success')
      return
    }
    if (order.status === OrderStatus.ERROR) {
      loadCustomer()
      nextStep('error')
      return
    }
    /* loadStates() */
    window.addEventListener('message', getMessageFromFrame)

    return () => {
      window.removeEventListener('message', getMessageFromFrame)
    }
  }, [])

  useEffect(() => {
    if (riskDisclosureAcceptedError) {
      riskDisclosureModalRef.current?.show()
    }
  }, [riskDisclosureAcceptedError])

  useEffect(() => {
    if (qrCode) {
      qrPaymentModalRef.current?.show()
    } else {
      qrPaymentModalRef.current?.hide()
    }
  }, [qrCode])

  useEffect(() => {
    if (customer.id) {
      loadCards()
    }
  }, [customer.id])

  useEffect(() => {
    if (
      step === 'pending' &&
      ['SUSPICIOUS', 'EXECUTED', 'SUCCESS'].includes(order.status)
    ) {
      nextStep('success')
    } else if (processingResult && step !== processingResult) {
      nextStep(processingResult)
    }
  }, [step, order])

  useEffect(() => {
    const exlcludedPostOrderParams = ['mId', 'kycInvolved', 'hasCard']

    const orderToPost = { ...order }
    exlcludedPostOrderParams.forEach((key) => {
      delete orderToPost[key]
    })

    const postOrderData: IPostOrderData = {
      action: 'orderData',
      data: {
        order: orderToPost,
        result: ['SUSPICIOUS', 'EXECUTED', 'SUCCESS'].includes(order.status)
          ? OrderStatus.SUCCESS
          : OrderStatus.ERROR,
      },
    }

    postMessage(postOrderData, 'orderDetails')
  }, [step, order])

  useEffect(() => {
    setPaymentError('')
    setApmError(false)
    setData()
    setIsKytVerificationError(false)
    setIsWalletStatusPending(false)

    return () => {
      if (walletStatusCheckTimeout.current) {
        clearTimeout(walletStatusCheckTimeout.current)
      }
    }
  }, [apm])

  useEffect(() => {
    if (order) {
      if (
        step === 'card' &&
        !orderError &&
        !kycRequired &&
        !waitingForKyc &&
        order.apm &&
        apm != cardOption.id &&
        !apms[order.apm]?.fields?.length &&
        isSf &&
        viewWithoutCheckout
      ) {
        initPayment({
          source: PaymentSource.APM,
          orderId: order.id,
          apm: {
            id: apm,
          },
        })
      }
    }
  }, [order.apm, kycRequired, waitingForKyc, orderError, step])

  useEffect(() => {
    if (formData) {
      if (billing && validateBillingAddress(true, false)) {
        saveBilling({ ...billingAddress, country: customer.country }).then(
          (res: any) => {
            if (res?.data?.success) {
              setCustomer({
                ...customer,
                billingAddress: {
                  ...billingAddress,
                  country: customer.country,
                },
              })
              setBilling(false)
              verifyWallet({ orderId: order.id })
            }
            return
          }
        )
      } else {
        if (apm === cardOption.id) {
          initPayment({
            source: PaymentSource.CardInternal,
            orderId: order.id,
            threeDs: 'ver2',
          })
        } else {
          initPayment(formData)
        }
      }
    }
  }, [formData])

  useEffect(() => {
    if (
      (isKytVerificationError && order.id) ||
      (isNeedWalletConfirmation && order.id)
    ) {
      verifyWallet({ orderId: order.id })
    }
  }, [isKytVerificationError, order.id, isNeedWalletConfirmation])

  useEffect(() => {
    if (billing) {
      setFormData(undefined)
    }
  }, [billing])

  useEffect(() => {
    if (customer.country && order.paymentCurrency && showApm) {
      loadAPMs({
        country: customer.country,
        currency: order.paymentCurrency,
        locale: lang,
        orderId: order.id,
      })
    }
    if (
      !customer.country &&
      storageResidence &&
      order.paymentCurrency &&
      showApm
    ) {
      loadAPMs({
        country: storageResidence?.code,
        currency: order.paymentCurrency,
        locale: lang,
        orderId: order.id,
      })
    }
  }, [customer.country, order.paymentCurrency, lang])

  useEffect(() => {
    if (paymentData.nextStep === PaymentNext.CardForm && formData) {
      makePay()
    }
  }, [paymentData, formData])

  const handleGooglePayPayment = async (
    token: string,
    cardholderName?: string
  ) => {
    const paymentId = paymentData.id

    if (cardholderName) {
      return payMobile({ paymentId, token, cardholder: cardholderName })
    }

    return payMobile({ paymentId, token })
  }

  useEffect(() => {
    if (orderError && orderError.type) {
      if (orderError.type === 'ORDER_ALREADY_PROCESSED') {
        setProcessingError(OrderProcessingError.ORDER_ALREADY_PROCESSED)
      } else {
        setServerError('somethingWentWrong')
      }
    }
  }, [orderError])

  useEffect(() => {
    if (!customer?.countryByIp && order.sf && !customer?.country)
      setCustomer({
        ...customer,
        countryByIp: countryByApm(order.apm),
        country: countryByApm(order.apm),
      })
  }, [customer?.countryByIp, customer?.country, order?.sf])

  useEffect(() => {
    if (countriesData.length) {
      if (customer.country) {
        const selectedCountry = countriesData.find(
          (c: any) => c.code === customer.country
        )
        if (selectedCountry && !selectedCountry.supported) {
          setNotSupportedCountry(selectedCountry)
        }
      }
    }
  }, [countriesData, customer.country])

  useEffect(() => {
    if (countriesData.length) {
      if (customerInfo.country) {
        const selectedCountry = countriesData.find(
          (c: any) => c.code === customerInfo.country
        )
        if (selectedCountry && !selectedCountry.supported) {
          setNotSupportedCountry(selectedCountry)
        }
      }
    }
  }, [countriesData, customerInfo.country])

  useEffect(() => {
    if (!statesData?.length || !customer.state) {
      return
    }

    const selectedState = statesData.find((state: ICountryState) => {
      return state.code === customer.state
    })

    if (selectedState && !selectedState.supported) {
      setNotSupportedState(true)
    }
  }, [statesData, customer.state])

  useEffect(() => {
    if (!customer.country || !order.currency) {
      return
    }

    if (
      customer.country === USA_CODE &&
      !currencies.find((c) => c.currency === order.currency)
    ) {
      setNotSupportedCurrency(true)
    }
  }, [customer.country, order.currency])

  const requestInProgress =
    residenceSaving ||
    paymentInProgress ||
    auth3d ||
    apmAmountConverting ||
    acceptingRiskDisclosure ||
    isWalletVerifying ||
    isCheckingWalletStatus ||
    isLoadingKycWalletToken ||
    isWalletStatusPending

  useEffect(() => {
    //Temp code check after 16/11/2021
    if (requestIsSent && process.env.REACT_APP_SENTRY_URL) {
      let timout: any
      if (formData && requestInProgress) {
        timout = setTimeout(() => {
          const { securityCode, ...other } = formData
          other.number = other.number.replace(
            other.number.substr(6, other.number.length - (4 + 6)),
            other.number
              .substr(6, other.number.length - (4 + 6))
              .replace(/./g, '*')
          )
          Sentry.captureException(new Error('Failing payment init'), {
            extra: { order, formData: other },
          })
        }, 7000)
      } else {
        timout && clearTimeout(timout)
        setRequestIsSent(false)
      }

      return () => {
        clearTimeout(timout)
      }
    }
  }, [requestIsSent, requestInProgress])

  const stepIsActive = RESULT_STEPS.includes(step)
  const isFinish = step && stepIsActive

  useEffect(() => {
    if (form3ds.action && !isFinish) {
      document.body.style.overflow = 'auto';
    }

    if (isFinish) {
      document.body.style.overflow = 'auto';
    }
  }, [form3ds, isFinish])

  if (unavailable) {
    return (
      <div className={classes.root}>
        <Typography variant="body1" align="center">
          <p>{t('serviceUnavailable')}</p>
          <Link
            className={clsx(formClasses.send, 'wide')}
            component={Button}
            href={getSupportEmail(MIRROR)}
          >
            {t('Inform administrator')}
          </Link>
        </Typography>
      </div>
    )
  }

  const hasApplePay = getHasApplePay(showApm, apms, customer.id)

  const needLocation =
    (!order.sf &&
      countriesData.length > 0 &&
      customer.id &&
      !customer.country &&
      (!storageResidence || storageResidence?.code === USA_CODE)) ||
    (withToken && !customer.country && countriesData.length > 0 && order.sf)

  function validateTermsAccept(
    formValid: boolean,
    errorFocused: boolean
  ): [boolean, boolean] {
    let valid = formValid
    let focus = errorFocused

    if (
      (withToken &&
        (!customer.termsAccepted || !customer.privacyPolicyAccepted) &&
        termsAcceptFieldRef.current &&
        // @ts-ignore
        !termsAcceptFieldRef.current.checked) ||
      // @ts-ignore
      (termsAcceptFieldRef.current && !termsAcceptFieldRef.current.checked)
    ) {
      setTermsError('validation.Required field')
      valid = false
      if (!errorFocused) {
        // @ts-ignore
        termsAcceptFieldRef.current.focus()
        if (termsAction) {
          termsAction.focusVisible()
        }
        focus = true
      }
    }
    return [valid, focus]
  }

  function validateCountryAndTerms(
    formValid: boolean,
    errorFocused: boolean
  ): [boolean, boolean] {
    const [valid, focus] = validateCountry(formValid, errorFocused)
    return validateTermsAccept(valid, focus)
  }

  if (notSupportedCountry && notSupportedCountry.name) {
    return (
      <ResidenceRestricted
        country={notSupportedCountry.name}
        order={order}
        canCancelOrder
      />
    )
  }

  if (notSupportedState) {
    return (
      <StateRestricted
        country={customer.country}
        state={customer.state}
        order={order}
      />
    )
  }

  if (notSupportedCurrency && customer.country) {
    return (
      <CurrencyRestricted
        fallBackUrl={order.failUrl}
        currency={order.currency}
        country={customer.country}
      />
    )
  }

  if (isKycRejected) {
    return <ComplianceScreen order={order} />
  }

  const addressAndTermsProps: IAddressAndTermsProps = {
    termsAcceptField: termsAcceptFieldRef,
    termsError: termsError ? t(`${termsError}`) : undefined,
    setTermsAction,
    addressAcceptField,
    setConfirmFormChange,
    setTermsError,
    customer,
    hasApplePay,
  }

  const remapedAPMs = filteredApms(apms).map((item: IAPM) => {
    const name =
      t(`apmDescription.${item.id}.name`) !== `apmDescription.${item.id}.name`
        ? t(`apmDescription.${item.id}.name`)
        : item.name
    return { ...item, name }
  })

  const selectedApm = remapedAPMs.find((item: IAPM) => item.id === apm) || {
    name: 'No Name',
  }
  const checkStatus = () => {
    checkPaymentStatus({ id: paymentData.id })
  }

  if (kycWalletToken) {
    return (
      <SumSubWalletVerification
        order={order}
        customer={customer}
        authToken={kycWalletToken}
        setIsNeedConfirmation={setIsNeedWalletConfirmation}
        setKycWalletToken={setKycWalletToken}
      />
    )
  }

  if (isNeedWalletConfirmation) {
    return (
      <WalletVerificationPending
        order={order}
        setPaymentError={setPaymentError}
        setResultError={setResultError}
        setIsNeedConfirmation={setIsNeedWalletConfirmation}
        nextStep={nextStep}
        onSuccess={() => {
          if (googlePayStarted) {
            initPayment({
              source: PaymentSource.APM,
              orderId: order.id,
              apm: { id: 'googlepay', inputs: {} },
            })
            return
          }
          if (apm === cardOption.id) {
            initPayment({
              source: PaymentSource.CardInternal,
              orderId: order.id,
              threeDs: 'ver2',
            })
          } else {
            initPayment(formData)
          }
          return
        }}
      />
    )
  }

  return (
    <PayFormLayout
      showApmView={showApmView}
      step={step}
      order={order}
      apmsLoaded={!showApm || apmsLoaded}
      customer={customer}
      token={withToken}
      apm={apm}
      limitAcceptError={limitAcceptError}
      acceptError={acceptError}
      isLoadForm={isLoadForm}
      loading={loading}
      customerLoading={customerLoading}
      loadingCards={loadingCards}
      loadingCountries={loadingCountries}
      stepIsActive={stepIsActive}
      apmDescriptionIsVisible={apmDescriptionIsVisible}
      setApmDescriptionIsVisible={setApmDescriptionIsVisible}
    >
      <RiskDisclosureModal
        ref={riskDisclosureModalRef}
        onPressCancel={() => {
          setRiskDisclosureAcceptedError('')
          riskDisclosureModalRef.current?.hide()
        }}
        onPressConfirm={() => {
          if (formData || googlePayStarted) {
            acceptRiskDisclosure({})
          } else {
            setRiskDisclosureAcceptedError('')
            riskDisclosureModalRef.current?.hide()
            setServerError('somethingWentWrong')
          }
        }}
      />
      <QRPaymentModal
        order={order}
        qrCode={qrCode}
        nextStep={nextStep}
        setQRCode={setQRCode}
        ref={qrPaymentModalRef}
        setResultError={setResultError}
      />
      {isFinish && (
        <PayFinish
          apm={remapedAPMs.find((a: IAPM) => a.id === apm)}
          step={step}
          nextStep={nextStep}
          order={order}
          customer={customer}
          logo={logo}
          error={resultError}
        />
      )}
      {!isFinish && !processingResult && (
        <div>
          {VERIFICATION_STEPS.includes(String(step)) && (
            <VerificationForm
              order={order}
              apm={apm}
              setStep={nextStep}
              t={t}
              ui={ui}
              customer={customer}
              setCustomer={setCustomer}
              email={customer.email}
              language={lang}
              paymentResult={paymentResult}
              setProcessingError={setProcessingError}
            />
          )}
          {!VERIFICATION_STEPS.includes(String(step)) &&
            limitAcceptError &&
            limitAcceptError.error.length > 0 && (
              <TXLimitError error={limitAcceptError} failUrl={order.failUrl} />
            )}
          {form3ds.action && !paymentResult && (
            <ThreeDS
              form={form3ds}
              className={classes.frame}
            />
          )}
          {formFingerprint && (
            <Fingerprint form={formFingerprint} checkStatus={checkStatus} />
          )}
          {!form3ds.action && !VERIFICATION_STEPS.includes(String(step)) && !limitAcceptError?.error && (
            <div>
              {serverError.length > 0 ? (
                <div>
                  <ServerMessage type="error" text={serverError} />
                  {order.failUrl && (
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ marginTop: 16 }}
                    >
                      <Button
                        className={globalClasses.button}
                        href={order.failUrl}
                        variant="contained"
                        color="primary"
                        id="back-to-merchant-fail"
                      >
                        {t('failBack')}
                      </Button>
                    </Typography>
                  )}
                </div>
              ) : (
                order.id && (
                  <div>
                    {paymentData.nextStep !== PaymentNext.ExternalUrl ||
                    billing ||
                    needLocation ? (
                      <div>
                        {paymentError.length > 0 && (
                          <ServerMessage type="error" text={paymentError} />
                        )}
                        {confirmFormChange && (
                          <FormChangeConfirm
                              order={order}
                              nextStep={nextStep}
                              setResultError={setResultError}
                              onConfirm={() => setCreateNewOrder(true)}
                              onCancel={() => setConfirmFormChange(false)}
                              setServerError={setServerError}
                          />
                        )}
                        {/* <div style={{ marginTop: 24 }} /> */}
                        {/*FIRST STEP*/}
                        {needLocation && (
                          <PayFirstStep
                            hasBilling={billing}
                            setHasBilling={setBilling}
                            billingAddress={billingAddress}
                            order={order}
                            lang={lang}
                            setTermsAction={setTermsAction}
                            termsError={termsError}
                            setTermsError={setTermsError}
                            customer={customer}
                            addressAcceptFieldRef={addressAcceptField}
                            countriesData={countriesData}
                            statesData={statesData}
                            onProceed={async (country, state) => {
                              const [termsValid] = validateTermsAccept(
                                true,
                                true
                              )

                              const isTermsAccepted =
                              (customer.termsAccepted && customer.privacyPolicyAccepted) || termsValid

                              if (!isTermsAccepted) {
                                return
                              }

                              try {
                                const countryRes = await saveCountry({
                                  country,
                                })

                                let success = countryRes?.data?.success

                                if (state) {
                                  const stateRes = await saveState({
                                    state,
                                  })
                                  success = success && stateRes?.data?.success

                                  if (billing) {
                                    saveBilling({
                                      ...billingAddress,
                                      country,
                                      state,
                                    }).then(() => {
                                      setBillingAddress(EMPTY_BILLING_ADDRESS)
                                      setBilling(false)
                                    })
                                  }
                                }

                                if (success) {
                                  setCustomer({
                                    ...customer,
                                    country,
                                    state,
                                    termsAccepted: true,
                                    privacyPolicyAccepted: true,
                                  })
                                } else {
                                  throw new Error('save country failed')
                                }
                              } catch (err) {
                                setServerError('somethingWentWrong')
                                console.error(err)
                              }
                            }}
                            termsAcceptFieldRef={termsAcceptFieldRef}
                            countryError={countryError}
                            detectedCountry={customer.countryByIp}
                            setConfirmFormChange={setConfirmFormChange}
                            setBillingData={(data: any) =>
                              setBillingAddress({
                                ...billingAddress,
                                ...data,
                              })
                            }
                            cityError={cityError}
                            setCityError={setCityError}
                            zipcodeError={zipcodeError}
                            setZipcodeError={setZipcodeError}
                            streetAddressError={streetAddressError}
                            setStreetAddressError={setStreetAddressError}
                            dateOfBirthError={dateOfBirthError}
                            firstNameError={firstNameError}
                            setFirstNameError={setFirstNameError}
                            lastNameError={lastNameError}
                            placeOfBirthError={placeOfBirthError}
                            setPlaceOfBirthError={setPlaceOfBirthError}
                            setDateOfBirthError={setDateOfBirthError}
                            setLastNameError={setLastNameError}
                          />
                        )}

                        {isUsFlow && (
                          <PayUSFlow
                            order={order}
                            customer={customer}
                            setCustomer={setCustomer}
                          />
                        )}

                        {/** SECOND STEP */}
                        {/** MOBILE PAYMENT */}
                        {!isUsFlow &&
                          !needLocation &&
                          (hasApplePay || hasGooglePay) &&
                          apm === 'cc_card' && (
                            <div>
                              {billing && !waitingForKyc && applePayStarted && (
                                <BillingFields
                                  classes={classes}
                                  setBillingData={(data: any) =>
                                    setBillingAddress({
                                      ...billingAddress,
                                      ...data,
                                    })
                                  }
                                  customer={customer}
                                  countries={countriesData}
                                  cityError={cityError}
                                  setCityError={setCityError}
                                  zipcodeError={zipcodeError}
                                  setZipcodeError={setZipcodeError}
                                  streetAddressError={streetAddressError}
                                  setStreetAddressError={setStreetAddressError}
                                  dateOfBirthError={dateOfBirthError}
                                  firstNameError={firstNameError}
                                  setFirstNameError={setFirstNameError}
                                  lastNameError={lastNameError}
                                  placeOfBirthError={placeOfBirthError}
                                  setPlaceOfBirthError={setPlaceOfBirthError}
                                  setDateOfBirthError={setDateOfBirthError}
                                  setLastNameError={setLastNameError}
                                />
                              )}
                              {(kycRequired || waitingForKyc) &&
                              (applePayStarted || googlePayStarted) ? (
                                <KYCStatus
                                  apm={remapedAPMs.find(
                                    (a: IAPM) => a.id === apm
                                  )}
                                  setStep={nextStep}
                                  customer={customer}
                                  setCustomer={setCustomer}
                                  kycRequired={kycRequired}
                                  waitingForKyc={waitingForKyc}
                                  order={order}
                                >
                                  <OrderDetails
                                    order={order}
                                    styles={paymentFormClasses}
                                  />
                                </KYCStatus>
                              ) : (
                                <MobilePay
                                  hasApplePay={hasApplePay}
                                  hasGooglePay={hasGooglePay}
                                  order={order}
                                  setCustomer={setCustomer}
                                  initPayment={initPayment}
                                  initPaymentMobile={initPaymentMobile}
                                  validateCountryAndTerms={
                                    validateCountryAndTerms
                                  }
                                  validateBillingAddress={
                                    validateBillingAddress
                                  }
                                  onGooglePayPayment={handleGooglePayPayment}
                                  onApplePayPayment={payMobile}
                                  requestInProgress={
                                    requestInProgress || paymentInProgress
                                  }
                                  paymentData={paymentData}
                                  isPayByCard={
                                    paymentData.nextStep ===
                                    PaymentNext.tokenGeneration
                                  }
                                  setApplePayStarted={() =>
                                    setApplePayStarted(true)
                                  }
                                  applePayStarted={applePayStarted}
                                  billing={billing}
                                  saveBillingForApplePay={(saveBillingCb: () => void) => {
                                    saveBilling({
                                      ...billingAddress,
                                      country: customer.country,
                                    }).then(
                                      (res: any) => {
                                        if (res?.data?.success) {
                                          setCustomer({
                                            ...customer,
                                            billingAddress: {
                                              ...billingAddress,
                                              country: customer.country,
                                            },
                                          })
                                          setBilling(false)
                                          saveBillingCb()
                                        }
                                      }
                                    )
                                  }}
                                  saveBilling={() => {
                                    saveBilling({
                                      ...billingAddress,
                                      country: customer.country,
                                    }).then(
                                      (res: any) => {
                                        if (res?.data?.success) {
                                          setCustomer({
                                            ...customer,
                                            billingAddress: {
                                              ...billingAddress,
                                              country: customer.country,
                                            },
                                          })
                                          verifyWallet({ orderId: order.id })
                                        }
                                      }
                                    )
                                  }}
                                  customer={customer}
                                  setPaymentError={setPaymentError}
                                  setGooglePayStarted={() =>
                                    setGooglePayStarted(true)
                                  }
                                  googlePayStarted={googlePayStarted}
                                  checkingKyc={checkingKyc}
                                  kycRequired={kycRequired}
                                />
                              )}
                            </div>
                          )}

                        {!isUsFlow &&
                          !needLocation &&
                          showApm &&
                          paymentData.nextStep !== PaymentNext.CardForm &&
                          !waitingForKyc &&
                          !applePayStarted &&
                            !googlePayStarted &&
                            !billing && (
                            <div>
                              <APMSelect
                                apms={remapedAPMs}
                                language={lang}
                                classes={classes}
                                error={!apm && apmError}
                                select={setApm}
                                apm={apm}
                                apmsLoaded={apmsLoaded}
                                step={step}
                                order={order}
                                country={customer.country}
                                loading={loadingAPMs}
                                // @ts-ignore
                                hideCard={isChainValley || apmConfig.apmsOnly[WIIDGET_ENV].includes(order.mId)}
                                apmLocked={!!order.apm}
                                onEmpty={() => nextStep('unavailable-apm')}
                              />

                              {apm && apm !== 'cc_card' && (
                                  <div className={classes.apmInfoWrapper}>
                                    <div className={classes.apmSelectSubInfo}>
                                      {!showApmView && (
                                        <div
                                          className={classes.apmSelectionInfo}
                                        >
                                        Provider fees can be charged.
                                        </div>
                                      )}
                                      {[
                                        'apmgw_Sofort',
                                        'apmgw_2C2P_APMs',
                                        'apmgw_UPI',
                                      ].includes(apm) && (
                                        <div
                                          className={
                                            classes.apmDescriptionShowButton
                                          }
                                          onClick={() =>
                                            setApmDescriptionIsVisible(true)
                                          }
                                        >
                                          {`See all ${selectedApm.name} payment options`}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                        {!isUsFlow &&
                          !needLocation &&
                          showApm &&
                          !apm &&
                          paymentData.nextStep !== PaymentNext.CardForm && (
                            <div>
                              <OrderDetails
                                order={order}
                                styles={paymentFormClasses}
                              />
                              <AddressAndTerms
                                customer={customer}
                                setConfirmFormChange={setConfirmFormChange}
                                addressAcceptField={addressAcceptField}
                                termsError={termsError}
                                setTermsError={setTermsError}
                                setTermsAction={setTermsAction}
                                termsAcceptField={termsAcceptFieldRef}
                              />
                              <div
                                className={globalClasses.payButtonWrapper}
                                onClick={() => !apm && setApmError(true)}
                              >
                                <Button
                                  className={clsx(
                                    'customButton',
                                    'Mui-disabled',
                                    globalClasses.button
                                  )}
                                  disabled={!apm}
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  // onClick={() => setApmApplied(true)} //TODO!!!!!!
                                >
                                  {t('Proceed')}
                                </Button>
                              </div>
                            </div>
                          )}

                        {/*THIRD STEP*/}
                        {!isUsFlow &&
                          !needLocation &&
                          apm && (
                            <div>
                              {billing && !applePayStarted && !waitingForKyc && (
                                <BillingFields
                                  customer={customer}
                                  classes={classes}
                                  setBillingData={(data: any) =>
                                    setBillingAddress({
                                      ...billingAddress,
                                      ...data,
                                    })
                                  }
                                  cityError={cityError}
                                  setCityError={setCityError}
                                  zipcodeError={zipcodeError}
                                  countries={countriesData}
                                  setZipcodeError={setZipcodeError}
                                  streetAddressError={streetAddressError}
                                  setStreetAddressError={setStreetAddressError}
                                  dateOfBirthError={dateOfBirthError}
                                  firstNameError={firstNameError}
                                  setFirstNameError={setFirstNameError}
                                  lastNameError={lastNameError}
                                  placeOfBirthError={placeOfBirthError}
                                  setPlaceOfBirthError={setPlaceOfBirthError}
                                  setDateOfBirthError={setDateOfBirthError}
                                  setLastNameError={setLastNameError}
                                />
                              )}
                              {apm === 'cc_card' &&
                                (kycRequired &&
                                (applePayStarted || googlePayStarted) ? null : (
                                  <CardForm
                                    cardId={cardId}
                                    setCardId={setCardId}
                                    classes={classes}
                                    cards={cards}
                                    setPaymentError={setPaymentError}
                                    paymentError={paymentError}
                                    disabled={false} //{needLocation || billing}
                                    setFormData={setFormData}
                                    validateBillingAddress={
                                      validateBillingAddress
                                    }
                                    order={order}
                                    validateCountryAndTerms={
                                      validateCountryAndTerms
                                    }
                                    buttonDisabled={paymentError.length > 0}
                                    requestInProgress={requestInProgress}
                                    replaceApplePayStarted={() =>
                                      setApplePayStarted(false)
                                    }
                                    {...addressAndTermsProps}
                                  />
                                ))}
                              {apm !== 'cc_card' && remapedAPMs.length > 0 && (
                                <div>
                                  {(kycRequired || waitingForKyc) && (
                                    <KYCStatus
                                      apm={remapedAPMs.find(
                                        (a: IAPM) => a.id === apm
                                      )}
                                      setStep={nextStep}
                                      customer={customer}
                                      setCustomer={setCustomer}
                                      kycRequired={kycRequired}
                                      waitingForKyc={waitingForKyc}
                                      order={order}
                                    >
                                      <OrderDetails
                                        order={order}
                                        styles={paymentFormClasses}
                                      />
                                    </KYCStatus>
                                  )}
                                  {!kycRequired && !waitingForKyc && (
                                    <APMForm
                                      order={order}
                                      step={step}
                                      apm={remapedAPMs.find(
                                        (a: IAPM) => a.id === apm
                                      )}
                                      showQRPaymentModal={() =>
                                        qrPaymentModalRef.current?.show()
                                      }
                                      paymentError={paymentError}
                                      setStep={nextStep}
                                      qrCode={qrCode}
                                      requestInProgress={requestInProgress}
                                      buttonDisabled={paymentError.length > 0}
                                      setFormData={(formData: any) => {
                                        setFormData(formData)
                                      }}
                                      validateTermsAccept={validateTermsAccept}
                                      validateBillingAddress={
                                        validateBillingAddress
                                      }
                                      // setCustomer={setCustomer}
                                      apmAmount={apmAmount}
                                      hasGooglePay={hasGooglePay}
                                      hasApplePay={hasApplePay}
                                      checkingKyc={checkingKyc}
                                      setPaymentError={setPaymentError}
                                      isBillingAddressVisible={billing}
                                      {...addressAndTermsProps}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    ) : (
                      <PaymentFrame
                        paymentData={paymentData}
                        apm={remapedAPMs.find((a: IAPM) => a.id === apm)}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          )}
        </div>
      )}
    </PayFormLayout>
  )
}

/**
 *
 * {needLocation && (
 *  показать выбор места откуда происходит заход
 * }
 *
 * {!needLocation && (hasApplePay || hasGooglePay) && apm === 'cc_card' && (
 *  показывать мобильные платежи
 * )
 *
 * {!needLocation && showApm && paymentData.nextStep !== PaymentNext.CardForm && !waitingForKyc &&  (
 *  показать выбор АПМ
 * )
 *
 * {!needLocation && showApm && apm && apm !== 'cc_card' && !waitingForKyc && paymentData.nextStep !== PaymentNext.CardForm && (
 *    показать описание АПМ
 * )
 *
 * {!needLocation && showApm && !apm && paymentData.nextStep !== PaymentNext.CardForm && (
 *    Условия и Кнопка
 * )
 *
 *
 *
 */
