import React from 'react'

import { useTranslation } from 'react-i18next'

import { Field } from 'react-final-form'

import { TextField } from 'final-form-material-ui'

import { getTranslatedError } from '@/helpers/getTranslatedError'

interface IInputProps {
  name: string;
  type?: string;
  label?: any;
  required?: boolean;
  fullWidth?: boolean;
  validate?: any;
  helperText?: string;
  disabled?: boolean;
  inputRef?: any;
  onFocus?: any;
  onBlur?: any;
  onInput?: any;
  placeholder?: string;
  parse?: any;
  format?: any;
  className?: any;
  autoComplete?: any;
  autoFocus?: boolean;
  tabindex?: number;
  inputProps?: any;
  InputProps?: any;
  InputLabelProps?: any;
  id?: string;
  onKeyUp?: any;
  onKeyDown?: any;
  errorText?: string;
  variant?: string
}

const ExtendTextField = ({ inputProps, errorText, ...rest }: any) => {
  const { t } = useTranslation()
  // Note delivering variables into TextField not clear, check the node_modules/final-form-material-ui/dist/final-form-material-ui.min.js
  const input = rest?.input || {}
  const meta = rest?.meta || {}
  const error = meta.error || errorText

  const translatedError = getTranslatedError(t, error)

  return (
    <TextField
      {...rest}
      input={{ ...inputProps, ...input }}
      meta={{
        ...meta,
        error: translatedError,
        touched: meta.touched || !!errorText,
      }}
    />
  )
}

export default ({ name, type, required, variant = 'filled', ...rest }: IInputProps) => (
  <Field
    name={name}
    type={type || 'text'}
    component={type === 'hidden' ? 'input' : ExtendTextField}
    required={required}
    // InputLabelProps={{ shrink: true }}
    variant={variant}
    {...rest}
  />
)
