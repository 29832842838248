import { RefObject, useEffect } from 'react'

interface IUseCloseOnClickOutsideProps {
  onClose: () => void
  isVisible: boolean
  targetRef: RefObject<HTMLElement>
  containerRef: RefObject<HTMLElement>
}

export default function useCloseOnClickOutside({
  onClose,
  isVisible,
  targetRef,
  containerRef,
}: IUseCloseOnClickOutsideProps) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !(
          targetRef.current?.contains(event.target as Node) ||
          containerRef.current?.contains(event.target as Node)
        )
      ) {
        onClose()
      }
    }

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isVisible, targetRef, containerRef])
}
