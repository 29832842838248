import React, { FC, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import MenuItem from '@material-ui/core/MenuItem'

import { LANGUAGE_LIST } from '@/constants'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import { isChainValley } from '@/helpers/mirrorHelper'
import useCloseOnClickOutside from '@/hooks/useCloseOnClickOutside'

import useLanguageSelectStyles from './hooks/LanguageSelectStyles'
import SelectedLanguage from './components/SelectedLanguage/SelectedLanguage'

const LanguageSelect: FC = () => {
  const { i18n } = useTranslation()

  const globalClasses = useGlobalStyles({ isChainValley })
  const classes = useLanguageSelectStyles()

  const languageListRef = useRef<HTMLDivElement | null>(null)
  const selectedLanguageRef = useRef<HTMLDivElement | null>(null)

  const [isOpenLanguageList, setIsOpenLanguageList] = useState(false)

  const handleCloseLanguageList = () => {
    setIsOpenLanguageList(false)
  }

  useCloseOnClickOutside({
    containerRef: languageListRef,
    isVisible: isOpenLanguageList,
    targetRef: selectedLanguageRef,
    onClose: handleCloseLanguageList,
  })

  const handleChangeLanguage = (language: string) => {
    window.localStorage.setItem('lang', language)
    i18n.changeLanguage(language)
    handleCloseLanguageList()
  }

  return (
    <div className={classes.wrapper}>
      <div
        ref={selectedLanguageRef}
        onClick={() => setIsOpenLanguageList((prev) => !prev)}
      >
        <SelectedLanguage
          language={i18n.language}
          isArrowUp={isOpenLanguageList}
        />
      </div>

      {isOpenLanguageList && (
        <div className={classes.list} ref={languageListRef}>
          {LANGUAGE_LIST.map((item) => (
            <MenuItem
              disableGutters
              key={item.value}
              onClick={() => handleChangeLanguage(item.value)}
              className={clsx(globalClasses.langItem, item.value)}
            >
              {item.label}
            </MenuItem>
          ))}
        </div>
      )}
    </div>
  )
}

export default LanguageSelect
