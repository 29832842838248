import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { OnChange, OnBlur } from 'react-final-form-listeners'
import { Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { NavLink } from 'react-router-dom'
import { IExchangeFormProps } from '../../Exchange'
import useFormStyles from '../../Exchange/forms/useFormStyles'
import { Checkbox, Currency, Input } from '../../../components/Form'
import ConfirmationCodeForm from '../../Exchange/forms/CodeForm'
import KycRejected from '../../Exchange/forms/KycRejected'
import {
  composeValidators,
  isEmail,
  isWalletAddress,
  required,
} from '../../../validators'
import { IOrder, useAcceptOrder } from '../../../api/order'
import { useCodeResend, useCustomerWallet } from '../../../api/customer'
import { OrderExtendedType } from '../../../api/private/orders'
import { useConvert, useInit } from '../../../api/merchant/order'
import Loader from '../../../components/Loader'
import Typography from '@material-ui/core/Typography'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import ServerMessage, {
  TServerMessageType,
} from '../../../components/UI/ServerMessage'
import useEmailSuggest from '../../../hooks/useEmailSuggest'
import FormHelperText from '@material-ui/core/FormHelperText'
import Link from '@material-ui/core/Link'
import globalConfig from '../../../config/global.json'
import { INTERNAL_LINK_TARGET_BLANK } from '../../../helpers/internalTarget'
import { currencyOptionMapper } from '../../../helpers/currency'
import { ICurrency } from '../../../types/currency'
import captchaRequest from '@/helpers/captcha';
import ResidenceRestricted from '@/views/Account/Profile/ResidenceRestricted'
import { getPrivacyPolicyLink, getTermsOfUseLink } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

interface IProps extends IExchangeFormProps {
  currencies: any[]
  currenciesLoading: boolean
  setUnavailable: any
  fromOrder?: boolean
  extTimestamp?: number
  extPublicKey?: string
  extSignature?: string
  extAlg?: string
}

const unavailableErrors = ['SERVICE_UNAVAILABLE_ON_THIS_SITE', 'UNAUTHORIZED']

export default function AuthForm({
  setStep,
  setEmail,
  email,
  order,
  t,
  setCustomer,
  customer,
  loadOrder,
  // address,
  setAddress,
  ui,
  language,
  currencies,
  currenciesLoading,
  sid,
  setOrder,
  setUnavailable,
  fromOrder,
  setProcessingError,
  extTimestamp,
  extPublicKey,
  extSignature,
  extAlg,
}: IProps) {
  const {
    form: {
      authorization: { fields },
      buttonName,
      buttonColor,
      borderRadius,
    },
    global: {
      palette: { border },
    },
  } = ui
  let setAmount: any
  let setPaymentAmount: any
  const formClasses = useFormStyles({ buttonColor, borderRadius, border })
  const globalClasses = useGlobalStyles()
  const [confirm, setConfirm] = useState(false)
  const [converting, setConverting] = useState(false)
  const [emailSuggestion, checkEmail] = useEmailSuggest()
  const [customerVerified, setCustomerVerified] = useState<
    undefined | boolean
  >()
  const [saveCustomerWallet, setSaveCustomerWallet] = useState<
    undefined | any
  >()
  const [hideTerms, setHideTerms] = useState(false)
  const [initial, setInitial] = useState({
    ...order,
    email,
    terms: false,
    addressAccept: false,
    address: order.address,
    saveCustomerWallet,
  })
  const [convertFiat] = useConvert(sid, (res: any) => {
    if (res.success && typeof setPaymentAmount === 'function') {
      setPaymentAmount(res.data)
    } else if (!res.success && unavailableErrors.includes(res.error.type)) {
      setUnavailable(true)
    }
    setConverting(false)
  })
  const [convertCrypto] = useConvert(sid, (res: any) => {
    if (res.success && typeof setAmount === 'function') {
      setAmount(res.data)
    } else if (!res.success && unavailableErrors.includes(res.error.type)) {
      setUnavailable(true)
    }
    setConverting(false)
  })
  const [initialConvertCrypto] = useConvert(sid, (res: any) => {
    if (res.success && typeof setAmount === 'function') {
      setInitial({ ...initial, amount: res.data })
    } else if (!res.success && unavailableErrors.includes(res.error.type)) {
      setUnavailable(true)
    }
    setConverting(false)
  })
  let convertRequestTimeout: any

  const {
    accept,
    accepting,
    errorType: serverErrorType,
    error: serverError,
    kycRejected,
    countryNotSupported,
  } = useAcceptOrder({
    setCustomer: (c) => (setCustomer ? setCustomer(c) : null),
    setOrder: () => null,
    setConfirm,
    loadOrder,
    order: {} as IOrder,
    customer: {},
    setProcessingError,
  })

  const [init, initing] = useInit((res: any) => {
    if (res.success) {
      if (setOrder) setOrder(res.data)
      captchaRequest(
        '/api/widget/v1/order/accept',
        accept,
        {
          email: email,
          id: res.data.id,
          currency: res.data.paymentCurrency,
        }
      )
    }
  })

  const [checkCustomer] = useCustomerWallet((res: any) => {
    if (res.success) {
      setCustomerVerified(true)
      if (res.data.termsAccepted) {
        setHideTerms(true)
      }
      !email && res.data.email && setEmail(res.data.email)
      // !address && setAddress(email);
    }
  })

  const [resendCode] = useCodeResend(() => {
    setConfirm(true)
  })

  useEffect(() => {
    if (!currenciesLoading && currencies.length) {
      const fiatCurrencies = currencies.filter(
        (c: any) => c.enabled && c.type === 'FIAT'
      )
      const cryptoCurrencies = currencies.filter(
        (c: any) => c.enabled && c.type === 'CRYPTO'
      )
      const initvals = {
        ...initial,
        currency: cryptoCurrencies[0].currency,
        paymentCurrency: fiatCurrencies[0].currency,
      }
      setInitial(initvals)
    }
  }, [currencies, currenciesLoading])

  // if order was processed - send auth code and set to code form
  useEffect(() => {
    if (!['NEW', 'ACCEPTED'].includes(order.status) && order.acceptedByEmail) {
      captchaRequest('/api/widget/v1/customer/code', resendCode,{ id: order.id, email: order.acceptedByEmail })
    }
  }, [order])

  // convert amount if create from existing order
  useEffect(() => {
    if (fromOrder) {
      setConverting(true)
      initialConvertCrypto({
        fromCurrency: order.paymentCurrency,
        toCurrency: order.currency,
        paymentAmount: order.paymentAmount,
        type: 'DEPOSIT',
      })
    }
  }, [fromOrder])

  useEffect(() => {
    checkCustomer({
      timestamp: extTimestamp, //|| timestamp,
      publicKey: extPublicKey, //|| publicKey,
      signature: extSignature, //|| signature,
      alg: extAlg,
    })
  }, [extTimestamp, extPublicKey, extSignature, extAlg])

  if (kycRejected) {
    return <KycRejected classes={formClasses} t={t} order={order} type="kyc" />
  }

  if (countryNotSupported) {
    return <ResidenceRestricted country={countryNotSupported} order={order} />
  }

  if (confirm) {
    return (
      <ConfirmationCodeForm
        order={order}
        email={email}
        t={t}
        setCustomer={setCustomer}
        setStep={setStep}
        customer={customer}
        formClasses={formClasses}
        saveCustomerWallet={saveCustomerWallet}
        extTimestamp={extTimestamp}
        extPublicKey={extPublicKey}
        extSignature={extSignature}
      />
    )
  }

  const disableEmailInput = !!order.acceptedByEmail || customerVerified

  return (
    <Loader loading={accepting || initing || currenciesLoading}>
      <Form
        onSubmit={(values) => {
          if (order.status !== 'NEW') {
            return captchaRequest('/api/widget/v1/customer/code', resendCode,{ email, id: order.id })
          }
          setSaveCustomerWallet(values.saveCustomerWallet)
          return captchaRequest('/api/merchant/v1/order/init',
            init,
            {
              type: OrderExtendedType.FIAT_TO_CRYPTO,
              currency: values.paymentCurrency,
              paymentCurrency: values.currency,
              amount: values.paymentAmount,
              externalId: sid + '-' + Date.now(),
              address: values.address,
            },
          undefined,
            {
              'X-AUTH-SID': sid,
              'X-AUTH-NONCE': Date.now()
            }
          )
        }}
        mutators={{
          setAmount: (args, state, tools) => {
            tools.changeValue(state, 'amount', () => args[0])
          },
          setPaymentAmount: (args, state, tools) => {
            tools.changeValue(state, 'paymentAmount', () => args[0])
          },
          setEmail: (args, state, { changeValue }) =>
            changeValue(state, 'email', () => args[0]),
        }}
        initialValues={initial}
        render={({ handleSubmit, submitting, values, form }) => {
          const currency = (
            values.currency
              ? currencies.find((c) => c.currency === values.currency)
              : {}
          ) as ICurrency
          const paymentCurrency = values.paymentCurrency
            ? currencies.find((c) => c.currency === values.paymentCurrency)
            : {}
          setAmount = form.mutators.setAmount
          setPaymentAmount = form.mutators.setPaymentAmount
          setEmail = form.mutators.setEmail

          return (
            <form className={formClasses.root} onSubmit={handleSubmit}>
              <div className={globalClasses.widgetTitle}>
                <Typography variant="h6" component="div">
                  {t('Buy')} {t('CBC')}
                </Typography>
              </div>
              {['NEW', 'ACCEPTED'].includes(order.status) ? (
                <div>
                  {serverError.length > 0 && (
                    <ServerMessage
                      type={serverErrorType as TServerMessageType}
                      text={serverError}
                    />
                  )}
                  <div className={formClasses.row}>
                    <Currency
                      inputName="paymentAmount"
                      selectName="paymentCurrency"
                      currencies={currencies
                        .filter(
                          (c: ICurrency) =>
                            c.enabled &&
                            (order.type ===
                              OrderExtendedType.CRYPTO_TO_WALLET ||
                              c.type === 'FIAT')
                        )
                        .map(currencyOptionMapper)}
                      label={
                        fields && fields.paymentCurrency.title[language].length
                          ? fields.paymentCurrency.title[language]
                          : t('labels.Sell')
                      }
                      styles={{}}
                      currenciesLoading={currenciesLoading}
                      inputEnabled={!converting}
                      max={paymentCurrency && paymentCurrency.depositMax}
                      locked={converting}
                      className={
                        values.paymentAmount &&
                        values.paymentAmount.toString().length > 10
                          ? 'text-small'
                          : ''
                      }
                    />
                    <OnChange name="paymentCurrency">
                      {(currency: string) => {
                        if (converting) return
                        clearTimeout(convertRequestTimeout)
                        setConverting(true)
                        convertCrypto({
                          fromCurrency: currency,
                          toCurrency: values.currency,
                          paymentAmount: values.paymentAmount,
                          type: 'DEPOSIT',
                        })
                        // @ts-ignore
                        if (
                          values.paymentAmount &&
                          values.paymentAmount.length
                        ) {
                          form.blur('paymentAmount')
                        }
                      }}
                    </OnChange>
                    <OnChange name="paymentAmount">
                      {(val: any) => {
                        const formState = form.getState()
                        if (formState.active !== 'paymentAmount') {
                          form.blur('paymentAmount')
                        } else {
                          if (converting) return
                          clearTimeout(convertRequestTimeout)
                          convertRequestTimeout = setTimeout(() => {
                            if (!val || parseFloat(val) === 0) {
                              return form.mutators.setAmount('')
                            }
                            setConverting(true)
                            convertCrypto({
                              fromCurrency: values.paymentCurrency,
                              toCurrency: values.currency,
                              paymentAmount: val,
                              type: 'DEPOSIT',
                            })
                          }, 800)
                        }
                      }}
                    </OnChange>
                    <Currency
                      inputName="amount"
                      selectName="currency"
                      currencies={currencies
                        .filter((c: any) => c.type === 'CRYPTO' && c.enabled)
                        .map((c: any) => c.currency)}
                      label={
                        fields && fields.currency.title[language].length
                          ? fields.currency.title[language]
                          : t('labels.Buy')
                      }
                      styles={{}}
                      currenciesLoading={currenciesLoading}
                      // min={currency && currency.depositMin}
                      // max={currency && currency.depositMax}
                      inputEnabled={!converting}
                      locked={converting}
                      isCBC
                      className={
                        values.amount && values.amount.toString().length > 10
                          ? 'text-small'
                          : ''
                      }
                    />
                    <OnChange name="currency">
                      {(currency: string) => {
                        if (converting) return
                        clearTimeout(convertRequestTimeout)
                        setConverting(true)
                        convertFiat({
                          fromCurrency: values.paymentCurrency,
                          toCurrency: currency,
                          amount: values.amount,
                          type: 'DEPOSIT',
                        })
                        // @ts-ignore
                        if (values.amount && values.amount.length) {
                          form.blur('amount')
                        }
                      }}
                    </OnChange>
                    <OnChange name="amount">
                      {(val: any) => {
                        const formState = form.getState()
                        if (formState.active !== 'amount') {
                          form.blur('amount')
                        } else {
                          if (converting) return
                          clearTimeout(convertRequestTimeout)
                          convertRequestTimeout = setTimeout(() => {
                            if (!val || parseFloat(val) === 0) {
                              return form.mutators.setPaymentAmount('')
                            }
                            setConverting(true)
                            convertFiat({
                              fromCurrency: values.paymentCurrency,
                              toCurrency: values.currency,
                              amount: val,
                              type: 'DEPOSIT',
                            })
                          }, 800)
                        }
                      }}
                    </OnChange>
                  </div>
                  <div>
                    <Input
                      name="address"
                      type="text"
                      label={
                        fields && fields.walletAddress.title[language].length
                          ? fields.walletAddress.title[language]
                          : t('labels.Wallet address')
                      }
                      fullWidth
                      disabled={!fromOrder && !!order.address}
                      validate={composeValidators(required, (v: string) => {
                        return isWalletAddress(
                          v,
                          currency.addressValidator as string
                        )
                      })}
                    />
                    <OnChange name="address">
                      {(v: string) => {
                        setAddress(v)
                      }}
                    </OnChange>
                  </div>
                  <div style={{ position: 'relative' }}>
                    <Input
                      name="email"
                      type="email"
                      label={t('labels.Email')}
                      fullWidth
                      validate={composeValidators(required, isEmail)}
                      disabled={disableEmailInput}
                      autoComplete="new-password"
                      inputProps={{
                        spellCheck: false,
                      }}
                    />
                    {!disableEmailInput && emailSuggestion.length > 0 && (
                      <FormHelperText variant="filled">
                        {t('Do you mean')}{' '}
                        <Link
                          onClick={() =>
                            form.mutators.setEmail(emailSuggestion)
                          }
                          className={formClasses.link}
                        >
                          {emailSuggestion}
                        </Link>
                        ?
                      </FormHelperText>
                    )}
                    <OnChange name="email">
                      {(v: string) => {
                        setEmail(v)
                        if (globalConfig.useEmailSuggest) {
                          checkEmail(form.getFieldState('email'))
                        }
                      }}
                    </OnChange>
                    <OnBlur name="email">
                      {() => {
                        if (globalConfig.useEmailSuggest) {
                          checkEmail(form.getFieldState('email'))
                        }
                      }}
                    </OnBlur>
                  </div>
                  {!hideTerms && (
                    <div className={globalClasses.checkBox}>
                      <Checkbox
                        label={
                          <Typography
                            variant="body2"
                            component="span"
                            className={globalClasses.checkBoxText}
                          >
                            <Trans i18nKey="acceptTermsAndPolicy">
                              I read and accept{' '}
                              <Link
                                href={getTermsOfUseLink(MIRROR)}
                                target={INTERNAL_LINK_TARGET_BLANK}
                              >
                                Terms of Use
                              </Link>{' '}
                              and{' '}
                              <Link
                                href={getPrivacyPolicyLink(MIRROR)}
                                target={INTERNAL_LINK_TARGET_BLANK}
                              >
                                Privacy Policy
                              </Link>
                            </Trans>
                          </Typography>
                        }
                        name="terms"
                        validate={(v: boolean) => required(v)}
                      />
                    </div>
                  )}
                  <div>
                    <Button
                      className={globalClasses.button}
                      disabled={submitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {buttonName[language] || t('Next')}
                    </Button>
                  </div>
                </div>
              ) : null}
            </form>
          )
        }}
      />
    </Loader>
  )
}
