import { LanguageItem } from '@/types'

import FlagFR from './../icons/flagFR.svg'
import FlagUS from './../icons/flagUS.svg'
import FlagPT from './../icons/flagPT.svg'
import FlagPL from './../icons/flagPL.svg'

export enum LanguagesEnum {
  En = 'en-US',
  Pl = 'pl-PL',
  Pt = 'pt-PT',
  Fr = 'fr-FR',
}

export const APM_LANGUAGES: Record<string, LanguagesEnum> = {
  blik: LanguagesEnum.Pl,
  BLK_WLT: LanguagesEnum.Pl,
  BLIK: LanguagesEnum.Pl,
  multibanco: LanguagesEnum.Pt,
  mbway: LanguagesEnum.Pt,
  MBW_WLT: LanguagesEnum.Pt,
  MUB_WLT: LanguagesEnum.Pt,
  MBW: LanguagesEnum.Pt,
  MUB: LanguagesEnum.Pt,
  SPX: LanguagesEnum.Pt,
  PIX: LanguagesEnum.Pt,
  PIX1: LanguagesEnum.Pt,
}

const LANGUAGES_MAP: { [key in LanguagesEnum]: LanguageItem } = {
  [LanguagesEnum.En]: {
    value: LanguagesEnum.En,
    icon: FlagUS,
    label: 'English',
  },
  [LanguagesEnum.Fr]: {
    value: LanguagesEnum.Fr,
    icon: FlagFR,
    label: 'Français',
  },
  [LanguagesEnum.Pt]: {
    value: LanguagesEnum.Pt,
    icon: FlagPT,
    label: 'Português',
  },
  [LanguagesEnum.Pl]: {
    value: LanguagesEnum.Pl,
    icon: FlagPL,
    label: 'Polski',
  },
}

export const LANGUAGE_LIST = Object.values(LANGUAGES_MAP)

export const DEFAULT_LANGUAGE = {
  icon: FlagUS,
  label: 'English',
  value: LanguagesEnum.En,
}
