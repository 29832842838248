import makeStyles from '@material-ui/core/styles/makeStyles'

const useLanguageSelectStyles = makeStyles(() => ({
  flag: {
    height: 16,
    width: 21,
    marginRight: 8,
  },
  list: {
    backgroundColor: '#fff',
    position: 'absolute',
    zIndex: 1000,
    bottom: 0,
    right: 0,
    padding: '8px 0px',
    borderRadius: '8px',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  },
  wrapper: {
    position: 'relative',
  },
  selectedLanguageWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
  label: {
    marginRight: 8,
  },
  arrowUp: {
    transform: 'rotate(180deg)',
  },
}))

export default useLanguageSelectStyles
