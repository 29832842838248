import React, { useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import Skeleton from '@material-ui/lab/Skeleton'
import Typography from '@material-ui/core/Typography'

import { ICustomer } from '@/types'
import { KycStatus } from '@/api/kyc'
import { IAPM } from '@/api/settings'
import { qaAttr } from '@/helpers/qaHelpers'
import { checkIsCCWOrder } from '@/helpers/localStorageUtils'
import NFTPreview from '@/components/NFTPreview'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import { OrderExtendedType } from '@/api/private/orders'
import useButtonApmStyles from '@/hooks/useButtonApmStyles'
import { IOrder, OrderStatus, useOrder } from '@/api/order'
import { isNonVerifiedKycStatus } from '@/helpers/kycHelper'
import { defineNFTFlow } from '@/helpers/nftHelpers/nftFlow'
import PayoutDetailsError from '@/views/Payout/components/PayoutDetailsError'

import {
  ErrorFinal,
  SuccessPayout,
  FinalScreenIcon,
  DocumentsVerification,
} from '../FinalScreen'
import OrderDetails from '../Details'
import usePayFinishStyles from './PayFinishStyles'
import PaymentDetails from './components/PaymentDetails'
import usePaymentFormStyles from '../usePaymentFormStyles'
import usePayFinishTracking from './hooks/usePayFinishTracking'

interface IPayFinishProps {
  apm?: IAPM
  step: string
  order: IOrder
  logo?: string
  error?: string
  customer: ICustomer
  nextStep: (step?: string) => void
}

export default function PayFinish({
  apm,
  step,
  order,
  error,
  customer,
  nextStep,
}: IPayFinishProps) {
  const { t } = useTranslation()

  const classes = usePayFinishStyles()
  const globalClasses = useGlobalStyles()
  const paymentFormClasses = usePaymentFormStyles()

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [{ data: updatedOrder }, loading, load] = useOrder()

  const isSuccess = ['success', 'pending'].includes(step)
  const isUnavailableApm = step === 'unavailable-apm'
  const isError = step === 'error'
  const isPayout = order.type === OrderExtendedType.WALLET_TO_FIAT
  const isNFTFlow = defineNFTFlow(order?.currency)
  const isShowPaymentDetails = order.apm === 'multibanco' && step === 'pending'
  const isNonVerifiedCustomerKycStatus = isNonVerifiedKycStatus(
    customer.kycStatus
  )
  const isSuccessfulPurchase = !isError && !isUnavailableApm && !isPayout
  const updatedOrderInfo = updatedOrder as IOrder

  const isCCW = checkIsCCWOrder(String(order.id))

  const buttonStyles = useButtonApmStyles({ apm: order?.apm || 'default' })

  usePayFinishTracking({
    step,
    order,
    customer,
    apmName: apm?.name,
    isSuccessfulPurchase,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    load({ id: order.id })
  }, [order.id])

  useEffect(() => {
    if (step !== 'pending') {
      return
    }

    timeoutRef.current = setInterval(() => {
      load({ id: order.id })
    }, 5000)

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
      }
    }
  }, [step, order.id])

  useEffect(() => {
    if (
      step === 'pending' &&
      [
        OrderStatus.SUSPICIOUS,
        OrderStatus.EXECUTED,
        OrderStatus.SUCCESS,
      ].includes(updatedOrderInfo.status)
    ) {
      nextStep('success')
      return
    }

    if (updatedOrderInfo.status === OrderStatus.ERROR) {
      nextStep('error')
      return
    }
  }, [updatedOrderInfo.status, step])

  if (
    !customer.kycStatus ||
    !isNonVerifiedCustomerKycStatus ||
    isUnavailableApm
  ) {
    return (
      <div className={classes.root}>
        {isShowPaymentDetails && <PaymentDetails order={order} />}
        {!isShowPaymentDetails && (
          <>
            <div className={classes.result}>
              <FinalScreenIcon step={step} />
            </div>
            {!isNFTFlow && (
              <div className={classes.result}>
                {loading ? (
                  <Skeleton variant="rect" width={180} />
                ) : (
                  <Typography
                    variant="h4"
                    component="span"
                    color="textPrimary"
                    translate="no"
                    {...qaAttr('final-amount-title')}
                  >
                    {isPayout
                      ? `${order.amount} ${order.currency}`
                      : `${
                          updatedOrderInfo?.finalPaymentAmount ||
                          updatedOrderInfo?.paymentAmount
                        } ${order.paymentCurrency}`}
                  </Typography>
                )}
              </div>
            )}
            {(isError || isUnavailableApm) && (
              <ErrorFinal
                customer={customer}
                apm={apm}
                order={order}
                isUnavailableApm={isUnavailableApm}
                error={error || (isPayout ? 'PROVIDER_ERROR' : undefined)}
                isPayout={isPayout}
              />
            )}
            {isSuccessfulPurchase && (
              <div className={classes.result}>
                {isSuccess &&
                customer.kycStatus &&
                isNonVerifiedCustomerKycStatus ? (
                  <Typography
                    variant="h5"
                    align="center"
                    component="div"
                    color="textPrimary"
                  >
                    {t('paymentResult.amountPreAuthorized')}
                  </Typography>
                ) : (
                  <>
                    <Typography
                      variant="h5"
                      align="center"
                      component="div"
                      color="textPrimary"
                    >
                      {t(
                        step === 'pending'
                          ? 'paymentResult.pendingTitle'
                          : 'paymentResult.successTitle'
                      )}
                    </Typography>
                    {isNFTFlow && (
                      <Typography
                        className={classes.subtitle}
                        variant="body2"
                        align="center"
                        component="div"
                        color="textSecondary"
                      >
                        {t('paymentResult.successNFTSubtitle')}
                      </Typography>
                    )}
                  </>
                )}
              </div>
            )}
            {isPayout && !isError && (
              <SuccessPayout order={order} styles={paymentFormClasses} />
            )}

            {isSuccess &&
              !isPayout &&
              !loading &&
              updatedOrderInfo &&
              updatedOrderInfo.status !== OrderStatus.EXECUTED &&
              updatedOrderInfo.status !== OrderStatus.SUCCESS &&
              customer.kycStatus &&
              ![
                KycStatus.TRUSTED,
                KycStatus.APPROVED,
                KycStatus.NEW,
                KycStatus.WAITING_FOR_APPROVAL,
                KycStatus.PENDING,
                KycStatus.REVIEWED,
                KycStatus.NONE,
              ].includes(customer.kycStatus) &&
              !updatedOrderInfo.sf && (
                <Typography
                  variant="body2"
                  align="center"
                  component="div"
                  className={classes.resultKyc}
                >
                  <p>
                    {t('verificationStatus')}{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(`kycStatusData.${customer.kycStatus}`),
                      }}
                    />
                  </p>
                  <p>{t('paymentResult.updatesWillSentToEmail')}</p>
                </Typography>
              )}
            {!isPayout && !isCCW && (
              <div className={paymentFormClasses.delim} />
            )}
            {order.nft && order.nftContract && !isNFTFlow && (
              <>
                <NFTPreview
                  id={Number(order.nft.id).toString()}
                  contract={order.nftContract}
                />
                <div className={paymentFormClasses.delim} />
              </>
            )}
          </>
        )}

        {!isPayout && !isCCW && (
          <OrderDetails order={updatedOrderInfo} styles={paymentFormClasses} />
        )}
        {isPayout && isError && (
          <PayoutDetailsError order={order} styles={paymentFormClasses} />
        )}
        {isSuccess && order.successUrl && (
          <div className={classes.buttonWrapper}>
            <Button
              className={clsx(globalClasses.button, isCCW && buttonStyles.root)}
              href={order.successUrl}
              variant="contained"
              color="primary"
              {...qaAttr('final-success-back-btn')}
            >
              {t(isPayout ? 'payout.successButton' : 'successBack')}
            </Button>
          </div>
        )}
        {!isSuccess && order.failUrl && (
          <div className={classes.buttonWrapper}>
            <Button
              className={clsx(globalClasses.button, isCCW && buttonStyles.root)}
              href={order.failUrl}
              variant="contained"
              color="primary"
            >
              {t(isUnavailableApm ? 'unavailableApm.button' : 'failBack')}
            </Button>
          </div>
        )}
      </div>
    )
  }

  return <DocumentsVerification order={updatedOrderInfo} />
}
