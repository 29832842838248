import snsWebSdk from '@sumsub/websdk'
import React, { useEffect, useState, useRef } from 'react'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'
import { KycStatus } from '../../../../api/kyc'
import { useKycToken, useKycTokenRefresh } from '../../../../api/private/kyc'
import { IOrder } from '../../../../api/order'
import Loader from '../../../../components/Loader'
import ServerMessage from '../../../../components/UI/ServerMessage'
import getBrowserLanguage from '../../../../helpers/tempSumSubLangRemaper'
import sentryConfig from '../../../../config/sentry.json'

interface IProps {
  customer: any
  setStep: any
  setCustomer?: any
  order?: IOrder
  reconfirm?: boolean
  preVerify?: boolean
  paymentResult?: string
}

const browserLang = getBrowserLanguage()

const sentryEnabled = process.env.REACT_APP_SENTRY_URL

export default function SumSubKyc({
  customer,
  setStep,
  setCustomer,
  order,
  reconfirm,
  preVerify,
  paymentResult,
}: IProps) {
  const [
    {
      data: { authToken },
      error,
    },
    loadingToken,
    loadToken,
  ] = useKycToken()
  const [, , refreshToken] = useKycTokenRefresh()

  const { t } = useTranslation()
  const [sumSubError, setSumSubError] = useState(false)
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    loadToken(order && { orderId: order.id })
  }, [])

  useEffect(() => {
    if (!container.current) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      window.scrollTo(0, 0)
    })

    resizeObserver.observe(container.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    if (authToken && authToken.length) {
      const snsWebSdkInstance = snsWebSdk
        .init(authToken, async (newAccessTokenCallback: any) => {
          try {
            const newToken = await refreshToken(order && { orderId: order.id })
            if (!newToken?.length && sentryEnabled) {
              Sentry.captureException(
                {
                  tokenRefreshError: 'Empty token',
                },
                { tags: { section: 'SumSub' } }
              )
              return
            }
            newAccessTokenCallback(newToken)
          } catch (e) {
            if (sentryEnabled) {
              Sentry.captureException(
                {
                  tokenRefreshError: JSON.stringify(e),
                },
                { tags: { section: 'SumSub' } }
              )
            }
          }
        })
        .withConf({
          lang: browserLang,
          email: customer.email,
          phone: customer.phone,
          i18n: {},
        })
        .on('onError', (e: any) => {
          if (sentryEnabled) {
            Sentry.captureException(e, { tags: { section: 'SumSub' } })
          }
          setSumSubError(true)
        })
        .onMessage((type: any, payload: any) => {
          if (
            sentryEnabled &&
            sentryConfig.sendSumsubInitializationInfo &&
            type === 'idCheck.onApplicantLoaded'
          ) {
            Sentry.captureException(
              { ...payload, description: 'Sumsub initialization info' },
              { tags: { section: 'SumSub' } }
            )
          }
          if (reconfirm && type === 'idCheck.onApplicantStatusChanged') {
            if (payload.reviewResult?.reviewAnswer === 'GREEN' && order) {
              if (typeof setCustomer === 'function') {
                setCustomer({ ...customer, kycStatus: KycStatus.APPROVED })
              }
              return setStep('card')
            }
          }
          if (
            preVerify &&
            [
              'idCheck.onApplicantSubmitted',
              'idCheck.onApplicantResubmitted',
              'idCheck.onApplicantStatusChanged',
            ].includes(type)
          ) {
            if (
              type !== 'idCheck.onApplicantStatusChanged' ||
              payload.reviewResult?.reviewAnswer === 'GREEN'
            ) {
              if (typeof setCustomer === 'function') {
                setCustomer({
                  ...customer,
                  kycStatus: KycStatus.WAITING_FOR_APPROVAL,
                })
              }
              return setStep('card')
            }
          }
          if (
            [
              'idCheck.onApplicantSubmitted',
              'idCheck.onApplicantResubmitted',
              'idCheck.onApplicantStatusChanged',
            ].includes(type)
          ) {
            if (
              type !== 'idCheck.onApplicantStatusChanged' ||
              payload.reviewResult?.reviewAnswer === 'GREEN'
            ) {
              if (typeof setCustomer === 'function') {
                setCustomer({
                  ...customer,
                  kycStatus: KycStatus.WAITING_FOR_APPROVAL,
                })
              }
              if (reconfirm && order) {
                return setStep('card')
              }
              setStep(paymentResult || 'success')
            }
          }
        })

      snsWebSdkInstance.build().launch('#sumsub-websdk-container')
    }
  }, [authToken, order])

  // Sumsub exception sending
  useEffect(() => {
    if (sentryEnabled && !loadingToken) {
      const exception: any = {
        description: 'FALSE condition on SumSub initialization',
        // kycFlow
      }
      let capture = false
      if (!authToken?.length || Object.keys(error)?.length) {
        exception.tokenError = Object.keys(error)?.length
          ? 'FETCH token error: ' + JSON.stringify(error)
          : 'Empty token'
        capture = true
      }
      // if (flowByCountry && !loadingCountries && (!countries.length || Object.keys(countiesError).length)) {
      //   exception.countriesError = Object.keys(countiesError).length
      //     ? 'FETCH countries error: ' + JSON.stringify(countiesError) : 'Empty countries list';
      //   capture = true;
      // }
      if (capture) {
        Sentry.captureException(exception, { tags: { section: 'SumSub' } })
      }
    }
  }, [loadingToken, authToken, error])

  return (
    <div>
      <Loader loading={loadingToken} />
      {(sumSubError || Object.keys(error).length > 0) && (
        <ServerMessage type="error" text={t('somethingWentWrong')} />
      )}
      <div id="sumsub-websdk-container" ref={container} />
    </div>
  )
}
