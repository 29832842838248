import { IOrder } from '@/api/order'
import { KycStatus } from '@/api/kyc'

import { isChainValley } from './mirrorHelper'

interface IOrderAmountVisibilityParams {
  step: string
  order: IOrder
  kycStatus: string
}

export const getIsShowOrderAmount = ({
  step,
  order,
  kycStatus,
}: IOrderAmountVisibilityParams) => {
  // For multibanco and mbway APMs, the order amount is hidden on pending screen
  // Order is centered
  const isOrderAmountHidden =
    step === 'pending' &&
    order?.apm &&
    ['multibanco', 'mbway'].includes(order?.apm) &&
    isChainValley

  const isShowOrderAmount =
    !['success', 'error', 'unavailable-apm', 'interrupted'].includes(step) &&
    //@ts-ignore
    ![KycStatus.WAITING_FOR_APPROVAL, KycStatus.PENDING].includes(kycStatus) &&
    !isOrderAmountHidden

  return isShowOrderAmount
}
