import { parsePhoneNumberFromString } from 'libphonenumber-js'
import WAValidator from 'trezor-address-validator'
import { Decode as XRPDecode } from 'xrpl-tagged-address-codec'
import cardValidator from 'card-validator'
import { bech32 } from 'bech32'
import axios from 'axios';

import i18n from '../i18n'
import plural from '../helpers/plural'

let udTlds: string[] = [];

(async function getUDtlds() {
  try {
    const { data: { tlds } } = await axios.get('https://resolve.unstoppabledomains.com/supported_tlds');
    udTlds = tlds;
  } catch (e) {
    console.error('Cant resolve unstoppable domains: ' + e.toString());
  }
})();

export const required = (value: any, isTranslated = false) => {
  if (!!value && String(value).trim().length) {
    return undefined
  }

  if (isTranslated) {
    return i18n.t('validation.Required field')
  }

  return 'validation.Required field'
}

export const mustBeNumber = (value: any) =>
  !value || isNaN(value) ? 'validation.Must be a number' : undefined

export const lengthRequired = (value: any, l: number, isTranslated = false) => {
  if (!value || (value && value.length === l)) {
    return undefined
  }

  if (isTranslated) {
    return i18n.t('validation.lengthErrParam', {
      length: l,
      chars: i18n.t(plural(l, ['charPlural1', 'charPlural2', 'charPlural5'])),
    })
  }

  return {
    key: 'validation.lengthErrParam',
    params: {
      length: l,
      chars: i18n.t(plural(l, ['charPlural1', 'charPlural2', 'charPlural5'])),
    },
  }
}

export const minLength = (value: any, l: number) =>
  value && value.length < l
    ? { key: 'validation.minLengthErr', params: { length: l } }
    : undefined

export const maxLength = (value: any, l: number, isTranslated = false) => {
  if (!value || (value && String(value).trim().length <= l)) {
    return undefined
  }

  if (isTranslated) {
    return i18n.t('validation.maxLengthErr', { length: l })
  }

  return { key: 'validation.maxLengthErr', params: { length: l } }
}

const emailRegexp =
  /^(([^<>()[\]\\.,;:!&%#'\s@"]+(\.[^<>()[\]\\.,;:!&%#'\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmail = (value: string, errorText = 'validation.emailErr') =>
  !value || emailRegexp.test(value.toLowerCase()) ? undefined : errorText

const passwordRegexp =
  /(?=^.{8,}$)(?=.*\d)(?=.*[~!@#$%^&*()_;:+-]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/

export const passwordValid = (value: string) => {
  if (!value || passwordRegexp.test(value)) {
    return undefined
  }

  return 'validation.passwordNotValid'
}

export const isPhoneNumber = (value: string, country?: string) => {
  const data = parsePhoneNumberFromString(value)
  if (!data) return i18n.t('validation.Invalid phone number')

  if (country && data.country && data.country !== country) {
    return i18n.t('validation.Invalid phone number')
  }

  if (data.isValid()) {
    return undefined
  }

  return i18n.t('validation.Invalid phone number')
}

export const isSsnInValid = (value: string) => {
  const isRegExpValid = /^\d{3}-?\d{2}-?\d{4}$/.test(value)
  if (!isRegExpValid) {
    return i18n.t('validation.invalidSsnFormat')
  }

  const [firstPart, secondPart, thirdPart] = value.split('-')

  if (['000', '666'].includes(firstPart)) {
    return i18n.t('validation.invalidSsnFormat')
  }

  if (Number(firstPart) >= 900) {
    return i18n.t('validation.invalidSsnFormat')
  }

  if ('00' === secondPart) {
    return i18n.t('validation.invalidSsnFormat')
  }

  if ('0000' === thirdPart) {
    return i18n.t('validation.invalidSsnFormat')
  }

  return undefined
}

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    )

export const isUDomain = (value: string) => {
  let uDomain = false;
  for (const t of udTlds) {
    if (value.endsWith('.' + t)) {
      uDomain = true;
      break;
    }
  }
  return uDomain;
}

export const isWalletAddress = (value: any, addressValidator: string) => {
  let currencyIsValid = isUDomain(value);
  if (!currencyIsValid) {
    const environment =
      process.env.REACT_APP_WIDGET_ENV === 'prod' ? 'prod' : 'testnet'

    if (addressValidator === 'TERRA') {
      currencyIsValid = !!value && /(terra1[a-z0-9]{38})/g.test(value)
    } else if (addressValidator === 'APT') {
      currencyIsValid = validateAptAddress(value)
    } else if ('COSMOS' === addressValidator) {
      currencyIsValid = isCosmosValidAddress(value)
    } else if ('NEAR' === addressValidator) {
      //!Important! Don't change position of wallet validation! If you are change browser will freeze
      currencyIsValid = !!value.match(new RegExp('^[a-f\\d]{64}$'))
      currencyIsValid = !currencyIsValid
        ? (value.includes('near') || value.includes('testnet')) &&
        !!value.match(
          new RegExp('^(([a-z\\d]+[-_])*[a-z\\d]+.)*(near|testnet)$')
        )
        : true
    } else if (addressValidator === 'XRP' && isNewXRPAddress(value)) {
      try {
        const { account, tag } = XRPDecode(value)
        currencyIsValid = !!account && !!tag
      } catch (err) {
        return err.message
      }
    } else {
      try {
        currencyIsValid =
          !!value &&
          WAValidator.validate(value.trim(), addressValidator, environment)
      } catch (err) {
        return err.message
      }
    }
  }

  return currencyIsValid ? undefined : 'validation.Invalid wallet address'
}

export const isCardNumber = (value: any, isTranslated = false) => {
  if (!value || cardValidator.number(value).isValid) {
    return undefined
  }

  if (isTranslated) {
    return i18n.t('validation.Invalid card number')
  }

  return 'validation.Invalid card number'
}

export const intervalValue = (value: any, min: number, max: number) =>
  value && parseFloat(value) >= min && parseFloat(value) <= max
    ? undefined
    : i18n.t('validation.valueBetween', { min, max })

export const minValue = (value: any, min: number) =>
  value && parseFloat(value) >= min
    ? undefined
    : i18n.t('validation.minValue', { min })

export const maxValue = (value: any, max: number) =>
  value && parseFloat(value) <= max
    ? undefined
    : i18n.t('validation.maxValue', { max })

export const availableValue = (value: any, max: number, min: number) => {
  if (value && parseFloat(value) <= max && parseFloat(value) >= min) {
    return undefined
  } else if (parseFloat(value) > max) {
    return i18n.t('validation.maxValue', { max })
  } else if (parseFloat(value) < min) {
    return i18n.t('validation.minValue', { min })
  }
}

export const onlyNumber = (value: any) =>
  isNaN(value) ? i18n.t('validation.Must be a number') : undefined

export const notEnoughBalance = (value: any, available: number) =>
  value && (isNaN(parseFloat(value)) || parseFloat(value) <= available)
    ? undefined
    : i18n.t('validation.notEnoughBalance')

export const equalWith = (value: any, equalTo: any) =>
  value && value === equalTo ? undefined : 'validation.passwordsNotEquals'

export const isValidCardExpires = (value: any) => {
  if (value.length) {
    const [month, year] = value.split('/')
    if (month > 12 || month < 1) {
      return i18n.t('validation.invalidExpiresDate')
    }
    const { isValid } = cardValidator.expirationDate({ month, year })
    return isValid ? undefined : i18n.t('validation.cardExpired')
  }
  return undefined
}

export const validateByRegex = (
  value: string,
  pattern: string,
  isTranslated = false
) => {
  const regex = new RegExp(pattern)

  if (regex.test(value)) {
    return undefined
  }

  if (isTranslated) {
    return i18n.t('validation.Invalid value')
  }

  return 'validation.Invalid value'
}

export const isJson = (item: any) => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item

  try {
    item = JSON.parse(item)
  } catch (e) {
    return false
  }

  if (typeof item === 'object' && item !== null) {
    return true
  }

  return false
}

export const isNewXRPAddress = (value: string): boolean => {
  return !!value?.match(/^X[0-9a-zA-Z]{46}/)
}

export const validateAptAddress = (address: string) => {
  return !!address?.match(/^(0x)?[a-zA-Z0-9]{1,64}$/)
}

const regexp = new RegExp('^(cosmos)1([qpzry9x8gf2tvdw0s3jn54khce6mua7l]+)$') // cosmos + bech32 separated by '1'

export const isCosmosValidAddress = (address: string) => {
  const match = regexp.exec(address)
  if (match !== null) {
    return verifyCosmosChecksum(address)
  } else {
    return false
  }
}

export const verifyCosmosChecksum = (address: string) => {
  try {
    const decoded = bech32.decode(address)
    if (decoded !== null) {
      return decoded.words.length === 32
    } else {
      return false
    }
  } catch (err) {
    return false
  }
}
