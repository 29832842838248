import React, { useEffect } from 'react'

import { Form } from 'react-final-form'

import { useTranslation } from 'react-i18next'

import { getApmView } from '@/helpers/getApmView'
import { trackClickPay, trackErrorScreen } from '@/helpers/analytics'
import { PaymentSource } from '@/api/payment'
import { isSf } from '@/helpers/constants'
import { getIsQRApm } from '@/helpers/getIsQRApm'

import AddressAndTerms from './AddressAndTerms'
import ApmFields from './ApmFields'
import OrderDetails from './Details'
import PayButton from './PayButton'
import usePaymentFormStyles from './usePaymentFormStyles'
import { IAPM, IAPMField } from '../../api/settings'
import Loader from '../../components/Loader'

interface IProps {
  apm?: IAPM;
  buttonDisabled: boolean;
  requestInProgress: boolean;
  validateTermsAccept(valid: boolean, focus: boolean): [boolean, boolean];
  validateBillingAddress(valid: boolean): boolean;
  apmAmount?: number;
  setFormData(data: any): void;
  hasGooglePay: boolean;
  checkingKyc: boolean;

  order: any;
  customer: any;
  addressAcceptField: any;
  setConfirmFormChange: any;
  setTermsError: any;
  termsAcceptField: any;
  setTermsAction: any;
  hasApplePay: any;
  termsError: any;
  paymentError?: string
  step: string
}

export default function APMForm(props: any /*IProps*/) {
  const {
    order,
    step,
    apm = {} as IAPM,
    buttonDisabled,
    paymentError,
    requestInProgress,
    customer,
    addressAcceptField,
    setConfirmFormChange,
    setTermsError,
    termsAcceptField,
    setTermsAction,
    termsError,
    validateTermsAccept,
    validateBillingAddress,
    hasApplePay,
    hasGooglePay,
    checkingKyc,
    apmAmount,
    setFormData,
    setPaymentError,
    isBillingAddressVisible,
    showQRPaymentModal,
    qrCode,
  } = props

  const paymentFormClasses = usePaymentFormStyles()

  const { t } = useTranslation()

  useEffect(() => {
    if (paymentError) {
      trackErrorScreen('error_screen', order, t(paymentError), apm?.name)
    }
  }, [paymentError])

  const showApmView = getApmView(step, order)

  const сheckedApmFields: IAPMField[] = []

  if (apm?.fields?.length > 0) {
    apm.fields.forEach((field: IAPMField) => {
      if (field?.defaultValue) {
        сheckedApmFields.push(field)
      }
    })
  }

  const isFormFilled = сheckedApmFields.length === apm?.fields?.length

  const getInitialValues = () => {
    if (apm?.fields?.length > 0) {
      return apm.fields
        .sort((a: IAPMField, b: IAPMField) => a.id.localeCompare(b.id))
        .reduce((acc: Record<string, string>, apmField: IAPMField) => {
          acc[apmField.id] = apmField.defaultValue || ''
          return acc
        }, {})
    }

    return null
  }

  return (
    <Loader loading={checkingKyc}>
      <div>
        <Form
          onSubmit={(inputs) => {
            if (validateBillingAddress(validateTermsAccept(true, false)[0])) {
              const apmFields = {
                id: apm.id,
                inputs,
              }

              if (apm.id === 'OBI' && !isSf) {
                return setPaymentError?.('somethingWentWrong')
              }

              if (qrCode && getIsQRApm(apm?.id)) {
                return showQRPaymentModal()
              }

              if ('phone' in inputs && inputs.phone.startsWith('+')) {
                inputs.phone = inputs.phone.slice(4).replace(/\s+/g, '')
              }

              setFormData({
                source: PaymentSource.APM,
                orderId: order.id,
                apm: apmFields,
              })
            }
          }}
          initialValues={getInitialValues()}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {apm?.fields?.length > 0 && (
                <ApmFields fields={apm.fields} isFormFilled={isFormFilled} />
              )}

              {!isBillingAddressVisible && (
                <OrderDetails order={order} styles={paymentFormClasses} />
              )}
              {(!hasApplePay || !hasGooglePay) && !isBillingAddressVisible && (
                <AddressAndTerms
                  customer={customer}
                  setConfirmFormChange={setConfirmFormChange}
                  addressAcceptField={addressAcceptField}
                  termsError={termsError}
                  setTermsError={setTermsError}
                  setTermsAction={setTermsAction}
                  termsAcceptField={termsAcceptField}
                />
              )}
              <div className={paymentFormClasses.payButtonWrapper}>
                <PayButton
                  onClick={() =>
                    trackClickPay(
                      'click_pay',
                      order,
                      apm?.name,
                      'Payment method'
                    )
                  }
                  disabled={buttonDisabled}
                  requestInProgress={requestInProgress}
                  amount={apmAmount || order.paymentAmount}
                  currency={order.paymentCurrency}
                  showApmView={showApmView}
                  apm={order?.apm}
                />
              </div>
            </form>
          )}
        />
      </div>
    </Loader>
  )
}
