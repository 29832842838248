import React from 'react'

import { useTranslation } from 'react-i18next'

import { IAPMField } from '@/api/settings'
import { Input, MaskedInput } from '@/components/Form'
import {
  required,
  lengthRequired,
  validateByRegex,
  composeValidators,
} from '@/validators'

import usePaymentFormStyles from '../usePaymentFormStyles'

interface IApmFieldsProps {
  fields: IAPMField[]
  isFormFilled: boolean
}

function ApmFields({ fields, isFormFilled = false }: IApmFieldsProps) {
  const { t } = useTranslation()

  const paymentFormClasses = usePaymentFormStyles()

  if (isFormFilled) return null

  const getFieldLabel = (field: IAPMField) => {
    if (field?.localizedName) {
      return t(`requiredField.${field.localizedName.toLowerCase()}`)
    }

    return t(field.id)
  }

  return (
    <div>
      <div className={paymentFormClasses.blockTitle}>
        {t('Payment Information')}
      </div>

      {fields.map((field) => {
        if (field.id === 'phone') {
          return (
            <MaskedInput
              key={field.id}
              fullWidth
              label={t('labels.phoneNumber')}
              customMask={'+351 999 999 999'}
              placeholder="+351 XXX XXX XXX"
              name={field.id}
              validate={composeValidators(required, (value: string) => {
                const parsedValue = value.slice(4).replace(/\s+/g, '')
                return lengthRequired(parsedValue, 9)
              })}
              type="text"
            />
          )
        }

        return (
          <Input
            key={field.id}
            name={field.id}
            label={getFieldLabel(field)}
            validate={composeValidators(required, (v: string) =>
              field.validation
                ? validateByRegex(v, field.validation)
                : undefined
            )}
            fullWidth
          />
        )
      })}
    </div>
  )
}

export default ApmFields
