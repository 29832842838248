import makeStyles from '@material-ui/core/styles/makeStyles'

interface IStylesProps {
  apm: string
  isOBIBackground?: boolean
}

function getBackground(apm: string, isOBIBackground: boolean) {
  if (isOBIBackground) {
    return '#FFF'
  }

  switch (apm) {
    case 'WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #862165'
    case 'NTL':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #76BA0E'
    case 'OBT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #66C18F'
    case 'MUB':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #246BB5'
    case 'SPX':
    case 'PIX1':
    case 'PIX':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #32BCAD'
    case 'SPI':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #323184'
    case 'BLK_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #343434'
    case 'blik':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #343434'
    case 'BLIK':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #343434'
    case 'MBW':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #D60510'
    case 'KHP':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #8347AD'
    case 'PGF':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #FC0'
    case 'PWY_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #E13134'
    case 'MAH':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #613DD2'
    case 'MCH_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #015AB9'
    case 'GIR_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #000268'
    case 'giropay':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #000268'
    case 'GIR':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #000268'
    case 'PSC':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #fff 38.69%), #3200ff'
    case 'OBI':
    case 'OBI_FT':
    case 'OBI_TR':
    case 'REV_FT':
    case 'REV_TR':
    case 'WS_FT':
    case 'WS_TR':
    case 'N26_FT':
    case 'N26_TR':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #fff 38.69%), #2f66e3'
    case 'MBW_WLT':
    case 'mbway':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #D60510'
    case 'MUB_WLT':
    case 'multibanco':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #246BB5'
    case 'IDL':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #cd0067'
    case 'ideal':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #cd0067'
    case 'IDL_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #cd0067'
    case 'BLB':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #2f66e3'
    case 'BLB_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #2f66e3'
    case 'EPS':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #cd0067'
    case 'EPS_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #cd0067'
    case 'NPY':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #cd0067'
    case 'SFT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #2f66e3'
    case 'SFT_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #2f66e3'
    case 'EPY':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #253982'
    case 'EPY_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #253982'
    case 'PCH':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #7887e6'
    case 'PCH_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #7887e6'
    case 'EUT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #0f2287'
    case 'EUT_WLT':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #0f2287'
    case 'bancontact':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #015ab9'
    case 'ONB':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #2f66e3'
    case 'CHP':
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #FFF 38.69%), #2f66e3'

    default:
      return '#FFF'
  }
}

const useApmViewStyles = makeStyles(() => ({
  root: ({ apm, isOBIBackground = false }: IStylesProps) => ({
    background: getBackground(apm, isOBIBackground),
  }),
}))
export default useApmViewStyles
