import React, { FC } from 'react'

import clsx from 'clsx'

import { DEFAULT_LANGUAGE, LANGUAGE_LIST } from '@/constants'

import useLanguageSelectStyles from '../../hooks/LanguageSelectStyles'

import Arrow from './../../../../icons/arrow.svg'

interface ISelectedLanguageProps {
  language: string
  isArrowUp: boolean
}

const SelectedLanguage: FC<ISelectedLanguageProps> = ({
  language,
  isArrowUp,
}) => {
  const classes = useLanguageSelectStyles()

  const selectedLanguage =
    LANGUAGE_LIST.find((item) => language === item.value) || DEFAULT_LANGUAGE

  return (
    <div className={classes.selectedLanguageWrapper}>
      <img src={selectedLanguage.icon} className={classes.flag} />
      <div className={classes.label}>{selectedLanguage.label}</div>
      <img src={Arrow} className={clsx(isArrowUp && classes.arrowUp)} />
    </div>
  )
}

export default SelectedLanguage
