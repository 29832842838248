import React from 'react'

import CopyToClipboard from 'react-copy-to-clipboard'

import { useSnackbar } from 'notistack'

import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'

interface ITextCopyProps {
  text: string
  iconSize?: number
  Icon?: React.ElementType
  showCopySnackbar?: boolean
}

export default function TextCopy({
  text,
  iconSize = 18,
  Icon = FileCopyIcon,
  showCopySnackbar = true,
}: ITextCopyProps) {
  const { enqueueSnackbar } = useSnackbar()

  const handleCopy = () => {
    if (showCopySnackbar) {
      enqueueSnackbar('Data copied', { variant: 'success' })
    }
  }

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <IconButton size="small">
        <Icon color="inherit" style={{ fontSize: iconSize }} />
      </IconButton>
    </CopyToClipboard>
  )
}
