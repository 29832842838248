import { TFunction } from 'i18next'

type IError = string | { key: string; params: Record<string, string | number> }

export const getTranslatedError = (t: TFunction, error?: IError) => {
  if (!error) {
    return undefined
  }

  if (typeof error === 'string') {
    return t(error)
  }

  return t(error.key, error.params)
}
