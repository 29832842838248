import React from 'react'

import { useTranslation } from 'react-i18next'

import { makeStyles, TextField, Typography } from '@material-ui/core'

import { IOrder } from '@/api/order'
import { getOrderAmount } from '@/helpers/getOrderAmount'
import { useTypedSelector } from '@/hooks/useTypedSelector'
import usePaymentFormStyles from '@/views/components/usePaymentFormStyles'

import CopyAdornment from './CopyAdornment'
import { getPaymentDetailsFromUrl } from '../../utils'

interface IPaymentDetailsProps {
  order: IOrder
}

function PaymentDetails({ order }: IPaymentDetailsProps) {
  const { t } = useTranslation()

  const convertedApm = useTypedSelector((state) => state.appData.convertedApm)

  const classes = useStyles()
  const paymentFormClasses = usePaymentFormStyles()

  const { entity, code } = getPaymentDetailsFromUrl()
  const amount = getOrderAmount(order, convertedApm?.apmAmount)

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {t('paymentDetails.title')}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {t('paymentDetails.description')}
      </Typography>
      <Typography variant="h6" className={classes.subtitle}>
        {t('paymentDetails.subtitle')}
      </Typography>

      <div className={paymentFormClasses.row}>
        <TextField
          value={entity}
          variant="filled"
          className={classes.field}
          label={t('paymentDetails.entity')}
          InputProps={{
            readOnly: true,
            endAdornment: <CopyAdornment text={entity} />,
          }}
        />
      </div>
      <div className={paymentFormClasses.row}>
        <TextField
          value={code}
          variant="filled"
          className={classes.field}
          label={t('paymentDetails.referenceCode')}
          InputProps={{
            readOnly: true,
            endAdornment: <CopyAdornment text={code} />,
          }}
        />
      </div>
      <div className={paymentFormClasses.row}>
        <TextField
          value={amount}
          variant="filled"
          label={t('Amount')}
          className={classes.field}
        />
      </div>
    </div>
  )
}

export default PaymentDetails

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#000',
  },
  title: {
    marginBottom: '10px',
    lineHeight: 1.18,
  },
  description: {
    marginBottom: theme.spacing(3),
    color: '#535F84',
    letterSpacing: '0.25px',
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.5,
    fontSize: '1rem',
  },
  field: {
    '& .MuiFilledInput-underline': {
      '&.Mui-focused': {
        backgroundImage: `linear-gradient(#F5F6F7 56px, ${theme.palette.primary.main} 2px) !important`,
      },
    },
  },
  button: {
    paddingTop: theme.spacing(2),
  },
}))
