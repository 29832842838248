import { useEffect } from 'react'

import { ICustomer } from '@/types'
import { IOrder } from '@/api/order'
import { trackPaymentResult } from '@/helpers/analytics'
import { isNonVerifiedKycStatus } from '@/helpers/kycHelper'

interface IUsePayFinishTrackingProps {
  step: string
  order: IOrder
  apmName?: string
  customer: ICustomer
  isSuccessfulPurchase: boolean
}

const usePayFinishTracking = ({
  step,
  order,
  apmName,
  customer,
  isSuccessfulPurchase,
}: IUsePayFinishTrackingProps) => {
  const isNonVerifiedCustomerKycStatus = isNonVerifiedKycStatus(
    customer.kycStatus
  )

  useEffect(() => {
    if (isSuccessfulPurchase && !isNonVerifiedCustomerKycStatus) {
      trackPaymentResult(
        step === 'pending' ? 'payment_pending' : 'payment_success',
        order,
        apmName,
        customer?.level
      )

      trackPaymentResult(
        step === 'pending' ? 'view_order_pending' : 'view_order_success',
        order,
        apmName,
        customer?.level
      )
    }
  }, [
    step,
    apmName,
    customer?.level,
    isSuccessfulPurchase,
    isNonVerifiedCustomerKycStatus,
  ])
}

export default usePayFinishTracking
