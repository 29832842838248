export const checkIsCCWOrder = (orderId: string) => {
  const orderIdCCW = window.localStorage.getItem('ccw')

  if (!orderIdCCW) {
    return false
  }

  return orderId === orderIdCCW
}

export function saveOrderIdCCWToLocalStorage(orderId: string) {
  window.localStorage.setItem('ccw', orderId)
}
