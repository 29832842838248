import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useAccountFormStyles } from '../../../../hooks/useAccountFormStyles';
import {Form} from 'react-final-form';
import {Input} from '../../../../components/Form';
import {required} from '../../../../validators';
import Button from '@material-ui/core/Button';
import plural from '../../../../helpers/plural';
import useGlobalStyles from '../../../../hooks/useGlobalStyles';
import Loader from '../../../../components/Loader';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

interface IProps {
  enabled: boolean;
  isConfirm: boolean;
  isDisabling: boolean;
  disable: any;
  enable: any;
  confirmDisable: any;
  confirmEnable: any;
  sending: boolean;
  onCancel: any;
  resendTimeout: number;
}

export default function OTPForm({ enabled, isConfirm, isDisabling, disable, enable, confirmDisable, confirmEnable, sending, onCancel, resendTimeout }: IProps) {
  const accountFormClasses = useAccountFormStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell colSpan={3} className="form">
        {enabled && (
          <div className={accountFormClasses.row}>
            <Typography variant="body2">{t('otpDisableHint')}</Typography>
            {!isConfirm && !isDisabling && (
              <Typography variant="body2">
                <Link className={accountFormClasses.link} onClick={disable}>
                  {t('acceptOTPRisk')}
                </Link>
              </Typography>
            )}
            {isDisabling && <Loader loading height={200} />}
          </div>
        )}
        {isConfirm && (
          <Form
            onSubmit={enabled ? confirmDisable : confirmEnable}
            initialValues={{ code: '' }}
            render={({ handleSubmit, invalid }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Input
                    name="code"
                    label={t('labels.Verification code')}
                    validate={(v: string) => required(v)}
                    fullWidth
                    autoFocus
                  />
                </div>
                <div className={accountFormClasses.row}>
                  <Button
                    className={globalClasses.button}
                    disabled={sending}
                    type="submit"
                    variant="contained"
                    component="button"
                    color="primary"
                  >
                    {t('Confirm')}
                  </Button>
                </div>
                <div className={accountFormClasses.row}>
                  <Button
                    className={globalClasses.button}
                    onClick={onCancel}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
                <Typography variant="body2" component="div" translate="no">
                  <p>{t('verificationCodeSent')}</p>
                  {resendTimeout > 0
                    ? (
                      <p>
                        {t('Resend confirmation code in')} {resendTimeout} {t(plural(resendTimeout, ['secPlural1', 'secPlural2', 'secPlural5']))}.
                      </p>
                    ) : (
                      <Link
                        onClick={() => enabled ? disable({}) : enable({})}
                        className={accountFormClasses.link}
                      >
                        {t('Resend code')}
                      </Link>
                    )}
                </Typography>
              </form>
            )}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
