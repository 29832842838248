import { makeStyles } from '@material-ui/core'

import { getButtonApmBorderRadius } from '@/helpers/mirrorHelper'

const usePayFinishStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
    color: 'rgba(0,0,0,0.6)',
  },
  result: {
    'textAlign': 'center',
    'marginBottom': theme.spacing(1),
    '& .MuiSkeleton-root': {
      margin: '0 auto',
      height: 34,
      [theme.breakpoints.down('xs')]: {
        height: 26,
      },
    },
  },
  logo: {
    'textAlign': 'center',
    'marginTop': theme.spacing(1),
    'marginBottom': theme.spacing(2),
    '& img': {
      maxHeight: 30,
    },
  },
  delim: {
    margin: '10px -40px',
    height: 1,
    background: theme.palette.divider,
  },
  resultKyc: {
    background: '#fefaed',
    margin: '0 -40px',
    padding: '26px 40px',
  },
  buttonWrapper: {
    marginTop: theme.spacing(8),
  },
  footer: {
    marginTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subtitle: {
    paddingTop: theme.spacing(2),
  },
  paymentDetailsButton: {
    'background': '#246BB5',
    'borderRadius': getButtonApmBorderRadius(),
    '&:hover': {
      backgroundColor: '#1f5ea0',
    },
    '&.Mui-disabled, &.customButton.Mui-disabled': {
      color: '#fff',
      backgroundColor: '#246BB5',
    },
  },
}))

export default usePayFinishStyles
