import { useEffect } from 'react'

import { IOrder } from '@/api/order'
import { getApmView } from '@/helpers/getApmView'
import { saveOrderIdCCWToLocalStorage } from '@/helpers/localStorageUtils'

interface IUseSaveCCWOrderProps {
  step: string
  order?: IOrder
}

export function useSaveCCWOrder({ step, order }: IUseSaveCCWOrderProps) {
  const isShowApmView = getApmView(step, order)

  useEffect(() => {
    if (isShowApmView && order?.id) {
      saveOrderIdCCWToLocalStorage(order.id)
    }
  }, [isShowApmView, order?.id])
}
