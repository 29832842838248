import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { APM_LANGUAGES } from '@/constants'

export default function useChangeLanguage(apm = '') {
  const { i18n } = useTranslation()

  useEffect(() => {
    const language = APM_LANGUAGES[apm]

    if (!language) {
      return
    }

    window.localStorage.setItem('lang', language)
    i18n.changeLanguage(language)
  }, [apm])
}
