import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import CBoxBase from '../../components/Form/CheckboxBase'
import useGlobalStyles from '../../hooks/useGlobalStyles'
import { INTERNAL_LINK_TARGET_BLANK } from '../../helpers/internalTarget'
import { qaAttr } from '../../helpers/qaHelpers'
import { IOrder } from '@/api/order'
import { defineNFTFlow } from '@/helpers/nftHelpers/nftFlow'
import { getPrivacyPolicyLink, getTermsOfUseLink } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

export interface IAddressAndTermsProps {
  addressAcceptField: any
  setConfirmFormChange(value: boolean): void
  setTermsError(value?: string): void
  termsAcceptField: any
  setTermsAction: any
  termsError?: string
  customer: any
  hasApplePay?: boolean
  order?: IOrder
}

export default function AddressAndTerms({
  addressAcceptField,
  termsAcceptField,
  setTermsAction,
  termsError,
  setTermsError,
  setConfirmFormChange,
  customer,
  order,
}: IAddressAndTermsProps) {
  const globalClasses = useGlobalStyles()
  const { t } = useTranslation()
  const isNFTFlow = defineNFTFlow(order?.currency)

  return (
    <div>
      <div className={globalClasses.checkBox}>
        <CBoxBase
          name="addressAccept"
          label={
            <Typography variant="body2" component="span">
              {isNFTFlow ? t('agreeToByNFT') : t('agreeToSendToWallet')}
            </Typography>
          }
          onClick={() => setConfirmFormChange(true)}
          checked
          inputRef={addressAcceptField}
          id="address-accept-checkbox"
          className={globalClasses.cbAddressAccept}
          {...qaAttr('address-accept-checkbox')}
        />
      </div>
      {(!customer.termsAccepted || !customer.privacyPolicyAccepted) && (
        <div className={globalClasses.checkBox}>
          <CBoxBase
            name="terms"
            label={
              <Typography
                variant="body2"
                className={globalClasses.checkBoxText}
                component="span"
              >
                <Trans i18nKey="acceptTermsAndPolicy">
                  I read and accept{' '}
                  <Link
                    href={getTermsOfUseLink(MIRROR)}
                    target={INTERNAL_LINK_TARGET_BLANK}
                  >
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    href={getPrivacyPolicyLink(MIRROR)}
                    target={INTERNAL_LINK_TARGET_BLANK}
                  >
                    Privacy Policy
                  </Link>
                </Trans>
              </Typography>
            }
            onChange={(e: any) =>
              setTermsError(
                e.target.checked ? undefined : 'validation.Required field'
              )
            }
            inputRef={termsAcceptField}
            setAction={setTermsAction}
            meta={
              termsError && {
                touched: true,
                invalid: true,
                error: termsError,
              }
            }
          />
        </div>
      )}
    </div>
  )
}
