import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'

import { setLogIn } from '@/store/actions/account'
import { KycStatus } from '@/api/kyc'
import {
  DocumentType,
  useKycDetails,
  useKycDocumentsRequest,
  usePrivateKycRequest,
} from '@/api/private/kyc'
import { Checkbox } from '@/components/Form'
import Loader from '@/components/Loader'
import ServerMessage from '@/components/UI/ServerMessage'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import { required } from '@/validators'
import useFormStyles from '../../Exchange/forms/useFormStyles'
import VerificationDocument from '../../Exchange/forms/verification/Document'
import DocumentTypeIcon from '../../Exchange/forms/verification/DocumentTypeIcon'
import SumSub from '../../Exchange/forms/verification/SumSub'
import ProofOfFunds from '../../ProofOfFunds'
import {
  IdPhotoType,
  PhotoTypes,
  useVerificationStyles,
} from '../../Exchange/forms/VerificationForm'
import { INTERNAL_LINK_TARGET_BLANK } from '@/helpers/internalTarget'
import { useCustomerInfo } from '@/api/customer'
import { getPartnerExternalId, getPartnerSid } from '@/helpers/kycHelper'
import { useMerchantSettings } from '@/api/merchant'
import { getKycAmlPolicyLink } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

export default function KYCPage() {
  const globalClasses = useGlobalStyles()
  const classes = useVerificationStyles()
  const formClasses = useFormStyles({})
  const { t } = useTranslation()
  const [{ data }, loading, load] = useKycDetails()
  const isCreate =
    !data ||
    !data.status ||
    [KycStatus.NONE, KycStatus.STARTED, KycStatus.TRUSTED].includes(data.status)
  const [idPhoto, setIdPhoto] = useState('')
  const ui = useSelector((state: any) => state.common.ui)
  const [selfiePhoto, setSelfiePhoto] = useState('')
  const [residencePhoto, setResidencePhoto] = useState('')
  const [backsidePhoto, setBacksidePhoto] = useState('')
  const [idPhotoType, setIdPhotoType] = useState('passport')
  const [, , loadCustomerInfo] = useCustomerInfo(setLogIn)
  const [, , loadUi] = useMerchantSettings()
  const [error, setError] = useState('')
  const action = isCreate ? usePrivateKycRequest : useKycDocumentsRequest
  const history = useHistory()
  const { user } = useSelector((state: any) => state.account)
  const externalId = getPartnerExternalId()
  const sid = getPartnerSid()

  const finish = () => {
    loadCustomerInfo()

    const merchantRedirect = ui?.global?.kyc_redirect
    const hasRedirect = Boolean(sid && externalId && merchantRedirect)

    if (hasRedirect) {
      window.location.href = merchantRedirect
    } else {
      history.push('/account/info')
    }
  }

  const [send] = action((res: any) => {
    if (res.success) {
      return finish()
    }
    setError('somethingWentWrong')
  })

  useEffect(() => {
    loadUi({ sid })
    load()
  }, [])

  let idCorrection: any = false
  let idCorrectionDoc: any
  let backsideCorrection: any
  let backsideCorrectionDoc: any
  let selfieCorrection: any = false
  let residenceCorrection: any = false
  let flow = ''

  if (data && data.corrections && data.corrections.length) {
    data.corrections.forEach((c: any) => {
      if (c.flow) {
        flow = c.flow
      }
      switch (c.type) {
        case DocumentType.ID_CARD:
          idCorrectionDoc = c.type
          idCorrection = c.comment
          if (idPhotoType !== IdPhotoType.ID) {
            setIdPhotoType(IdPhotoType.ID)
          }
          break
        case DocumentType.DRIVING_LICENSE:
          idCorrectionDoc = c.type
          idCorrection = c.comment
          if (idPhotoType !== IdPhotoType.DriverLicense) {
            setIdPhotoType(IdPhotoType.DriverLicense)
          }
          break
        case DocumentType.PASSPORT:
          idCorrectionDoc = c.type
          idCorrection = c.comment
          if (idPhotoType !== IdPhotoType.Passport) {
            setIdPhotoType(IdPhotoType.Passport)
          }
          break
        case DocumentType.ID_CARD_BACKSIDE:
        case DocumentType.DRIVING_LICENSE_BACKSIDE:
          backsideCorrection = c.comment
          backsideCorrectionDoc = c.type
          break
        case DocumentType.ID_CARD_SELFIE:
          selfieCorrection = c.comment
          break
        case DocumentType.RESIDENCE:
          residenceCorrection = c.comment
          break
        case DocumentType.PROOF_OF_FUND:
          flow = 'pof'
          break
      }
    })
  }

  useEffect(() => {
    if (data.id && user.id) {
      if (
        data.status === KycStatus.CORRECTION_REQUESTED &&
        user.thirdPartyKYC
      ) {
        return
      }
      if (
        !isCreate &&
        !idCorrection &&
        !selfieCorrection &&
        !residenceCorrection &&
        !backsideCorrection
      ) {
        finish()
      }
    }
  }, [data, user])

  function changeIdType(type: IdPhotoType) {
    setIdPhotoType(type)
    setIdPhoto('')
    setBacksidePhoto('')
  }

  if (flow === 'pof') {
    return (
      <ProofOfFunds setCustomer={() => null} customer={user} setStep={finish} />
    )
  }

  if (user.thirdPartyKYC) {
    return <SumSub customer={user} setStep={finish} />
  }

  return (
    <Loader loading={loading}>
      <Typography variant="h4" className={globalClasses.title}>
        {t('verification')}
      </Typography>
      <Form
        onSubmit={() => {
          let documents: any = {}
          if (isCreate) {
            if (
              !idPhoto.length ||
              !selfiePhoto.length ||
              (idPhotoType !== IdPhotoType.Passport && !backsidePhoto.length)
            ) {
              return setError('uploadAllDocs')
            }
            const id = idPhoto.split(',')[1]
            documents = {
              [idPhotoType]:
                idPhotoType === IdPhotoType.Passport
                  ? id
                  : { front: id, back: backsidePhoto.split(',')[1] },
              selfie: selfiePhoto.split(',')[1],
            }
            return send(documents)
          }
          if (idCorrection) {
            if (!idPhoto.length) {
              return setError('uploadIdPhoto')
            }
            documents[idCorrectionDoc] = idPhoto.split(',')[1]
          }
          if (backsideCorrection) {
            if (!backsidePhoto.length) {
              return setError('uploadBacksidePhoto')
            }
            documents[DocumentType.ID_CARD_BACKSIDE] =
              backsidePhoto.split(',')[1]
          }
          if (selfieCorrection) {
            if (!selfiePhoto.length) {
              return setError('uploadSelfiePhoto')
            }
            documents[DocumentType.ID_CARD_SELFIE] = selfiePhoto.split(',')[1]
          }
          if (residenceCorrection) {
            if (!residencePhoto.length) {
              return setError('uploadResidencePhoto')
            }
            documents[DocumentType.RESIDENCE] = residencePhoto.split(',')[1]
          }
          send({ documents })
        }}
        render={({ handleSubmit, submitting }) => (
          <div className={formClasses.root}>
            {isCreate && (
              <div className={classes.documentTypeSelect}>
                <div
                  className={clsx(classes.documentTypeItem, 'passport', {
                    active: idPhotoType === IdPhotoType.Passport,
                  })}
                  onClick={() => changeIdType(IdPhotoType.Passport)}
                >
                  <DocumentTypeIcon type={IdPhotoType.Passport} />
                  <Typography variant="body2" component="div">
                    <strong>{t('Passport')}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    {t('Fase photo page')}
                  </Typography>
                </div>
                <div
                  className={clsx(classes.documentTypeItem, 'id', {
                    active: idPhotoType === IdPhotoType.ID,
                  })}
                  onClick={() => changeIdType(IdPhotoType.ID)}
                >
                  <DocumentTypeIcon type={IdPhotoType.ID} />
                  <Typography variant="body2" component="div">
                    <strong>{t('National ID card')}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    {t('Front and back')}
                  </Typography>
                </div>
                <div
                  className={clsx(classes.documentTypeItem, 'driver', {
                    active: idPhotoType === IdPhotoType.DriverLicense,
                  })}
                  onClick={() => changeIdType(IdPhotoType.DriverLicense)}
                >
                  <DocumentTypeIcon type={IdPhotoType.DriverLicense} />
                  <Typography variant="body2" component="div">
                    <strong>{t('Drivers license')}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    {t('Front and back')}
                  </Typography>
                </div>
              </div>
            )}
            <div>
              {(isCreate || idCorrection) && (
                <div className={classes.document}>
                  <VerificationDocument
                    title={t(
                      idCorrectionDoc || `verificationTitle.${idPhotoType}`
                    ).toString()}
                    onLoad={setIdPhoto}
                    type={PhotoTypes.ID}
                    photo={idPhoto}
                    correction={idCorrection}
                    template="profile"
                    idPhotoType={idPhotoType as IdPhotoType}
                  />
                </div>
              )}
              {((isCreate && idPhotoType !== IdPhotoType.Passport) ||
                backsideCorrection) && (
                <div className={classes.document}>
                  <VerificationDocument
                    title={t(
                      backsideCorrectionDoc ||
                        `verificationTitle.backside-${idPhotoType}`
                    ).toString()}
                    onLoad={setBacksidePhoto}
                    type={PhotoTypes.Backside}
                    photo={backsidePhoto}
                    correction={backsideCorrection}
                    template="profile"
                    idPhotoType={idPhotoType as IdPhotoType}
                  />
                </div>
              )}
              {(isCreate || selfieCorrection) && (
                <div className={classes.document}>
                  <VerificationDocument
                    title={t(`labels.Selfie-${idPhotoType}`).toString()}
                    description={t('selfiePhotoDescription').toString()}
                    onLoad={setSelfiePhoto}
                    type={PhotoTypes.Selfie}
                    photo={selfiePhoto}
                    correction={selfieCorrection}
                    template="profile"
                    idPhotoType={idPhotoType as IdPhotoType}
                  />
                </div>
              )}
              {residenceCorrection && (
                <div className={classes.document}>
                  <VerificationDocument
                    title={t('labels.Proof of residence').toString()}
                    description={t('residencePhotoDescription').toString()}
                    onLoad={setResidencePhoto}
                    type={PhotoTypes.Residence}
                    photo={residencePhoto}
                    correction={residenceCorrection}
                    template="profile"
                  />
                </div>
              )}
            </div>
            {isCreate && (
              <div className={globalClasses.checkBox}>
                <Checkbox
                  label={
                    <span>
                      {t('labels.I agree with')}{' '}
                      <Link
                        href={getKycAmlPolicyLink(MIRROR)}
                        target={INTERNAL_LINK_TARGET_BLANK}
                      >
                        {t('KYC / AML policy')}
                      </Link>
                    </span>
                  }
                  name="terms"
                  validate={(v: boolean) => required(v)}
                />
              </div>
            )}
            <div className={classes.actions}>
              {error.length > 0 && (
                <ServerMessage type="error" text={error} isAccount />
              )}
              <Button
                className={globalClasses.button}
                disabled={submitting}
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        )}
      />
    </Loader>
  )
}
