export const WIIDGET_ENV = process.env.REACT_APP_WIDGET_ENV || 'dev'
export const IS_PROD = process.env.REACT_APP_WIDGET_ENV === 'prod'

export const MIRROR = process.env.REACT_APP_MIRROR || 'utorg'

export const VIEW_FOR_APMS = [
  'WLT',
  'NTL',
  'OBT',
  'MUB',
  'SPX',
  'PIX',
  'SPI',
  'BLK_WLT',
  'blik',
  'BLIK',
  'MBW',
  'KHP',
  'PGF',
  'PWY_WLT',
  'MAH',
  'MCH_WLT',
  'GIR_WLT',
  'PSC',
  'OBI',
  'OBI_FT',
  'OBI_TR',
  'REV_FT',
  'REV_TR',
  'WS_FT',
  'WS_TR',
  'N26_FT',
  'N26_TR',
  'MBW_WLT',
  'mbway',
  'MUB_WLT',
  'multibanco',
  'EUT',
  'EUT_WLT',
  'IDL',
  'IDL_WLT',
  'BLB',
  'BLB_WLT',
  'EPS',
  'EPS_WLT',
  'NPY',
  'SFT',
  'SFT_WLT',
  'EPY',
  'EPY_WLT',
  'PCH',
  'PCH_WLT',
  'ideal',
  'giropay',
  'bancontact',
  'GIR',
  'ONB',
  'CHP',
  'PIX1',
]

export const isSf = window.location.search.includes('sf=true')

export const VERIFICATION_STEPS = [
  'kyc-corrections',
  'pre-verification',
  'verification',
]

export const RESULT_STEPS = [
  'success',
  'error',
  'interrupted',
  'pending',
  'unavailable-apm',
]
