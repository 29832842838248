import React from 'react'

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

import TextCopy from '@/components/TextCopy'

interface ICopyAdornmentProps {
  text: string
}

function CopyAdornment({ text }: ICopyAdornmentProps) {
  return (
    <TextCopy
      text={text}
      iconSize={24}
      showCopySnackbar={false}
      Icon={FileCopyOutlinedIcon}
    />
  )
}

export default CopyAdornment
